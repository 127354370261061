import * as React from "react"
import { Button } from "../components/Button"
import { Carousel } from "../components/Carousel"
import { Container } from "../components/Container"
import { Meta } from "../components/Meta"
import { Navigation } from "../components/Page"
import { Timeline } from "../components/Timeline"
import { Viewer } from "../components/Viewer"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, useHashFragment, usePage } from "../hooks"

const activityDefaultIcon = "https://placehold.co/24x24.jpg?text=I&font=roboto"
const galleryDefaultThumbnail = "/image/placeholder-1280x720.png"
const partnerDefaultLogo = "/image/placeholder-1080x1080.png"

export default function About(): React.JSX.Element {
  const [page] = usePage({
    slug: "about-us"
  })
  const [navItems] = React.useState([
    {
      to: "/about-us#profile",
      content: "What is STA",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/about-us#activity",
      content: "Understand what we do",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/about-us#milestone",
      content: "Our Story",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/about-us#partnership",
      content: "Alliance & Partnership",
      ref: React.useRef<HTMLDivElement>()
    },
    {
      to: "/the-council",
      content: "Meet The Council",
    },
    {
      to: "/constitution",
      content: "Constitution",
    }
  ])
  const [profile, setProfile] = React.useState({
    opened: false,
  })
  const [commitment, setCommitment] = React.useState({
    items: []
  })
  const [activity, setActivity] = React.useState({
    items: []
  })
  const [milestone, setMilestone] = React.useState({
    items: []
  })
  const [gallery, setGallery] = React.useState({
    items: []
  })
  const [partner, setPartner] = React.useState({
    opened: false,
    items: []
  })

  React.useEffect(() => {
    const commitmentItems = page.detail.fields.collectionVal("COMMITMENT_ITEMS")
    const activityItems = page.detail.fields.collectionVal("ACTIVITY_ITEMS")
    const milestoneItems = page.detail.fields.collectionVal("MILESTONE_ITEMS")
    const galleryItems = page.detail.fields.collectionVal("GALLERY_ITEMS")
    const partnerItems = page.detail.fields.collectionVal("PARTNER_ITEMS")

    setCommitment((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: commitmentItems.map((commitment, i: number) => {
          return {
            order: commitment.textVal("order", `${i + 1}`.padStart(2, "0")),
            name: commitment.textVal("name", ""),
            description: commitment.textVal("description"),
          }
        }),
      }
    })
    setActivity((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: activityItems.map((activity) => {
          return {
            name: activity.textVal("name", ""),
            description: activity.textVal("description"),
            icon: activity.tryTextVal("icon", activityDefaultIcon),
          }
        }),
      }
    })
    setMilestone((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: milestoneItems.map((milestone) => {
          return {
            year: milestone.textVal("year", ""),
            description: milestone.textVal("description"),
          }
        }),
      }
    })
    setGallery((prevState) => {
      return {
        ...prevState,
        items: galleryItems.map((gallery) => {
          return {
            title: gallery.textVal("title", ""),
            description: gallery.textVal("description"),
            thumbnail: gallery.tryTextVal("thumbnail", galleryDefaultThumbnail),
          }
        }),
      }
    })
    setPartner((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: partnerItems.map((partner) => {
          return {
            name: partner.textVal("name", ""),
            logo: partner.tryTextVal("logo", partnerDefaultLogo),
          }
        }),
      }
    })
  }, [page])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "About Us"}`,
          description: `The Singapore Transport Association (STA) follows an initiative to represent the land transport industry. Since 1937, our goal to this day continues to be one of forging trade ties and generating business opportunities, as well as an entity to seek benefits and raise concerns with the government.`,
          image: page.detail.fields.tryTextVal("PROFILE_IMAGE_SRC", `/image/sta-team.jpg`)
        }}
      </Meta>

      <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
        <div className="w-full h-full flex justify-start items-center">
          <Container size="md">
            <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
              {page.detail.title || "About Us"}
            </p>
          </Container>
        </div>
      </div>

      <Navigation items={navItems} loading={page.loading} sticky />

      <div ref={navItems[0].ref} id="profile" className="scroll-my-28">
        {
          page.detail.fields.boolVal("PROFILE_VISIBILITY") &&
          <>
            <div></div> {/* @note: do not remove to avoid newlseter section contain below element attributes */}
            <div className="bg-white py-10 md:py-20">
              <Container size="md">
                <div className="flex flex-col gap-4 md:gap-8">
                  <div className="flex flex-col gap-4 md:gap-8">
                    <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                      {page.detail.fields.textVal("PROFILE_HEADING_TEXT", `Who We Are`)}
                    </p>
                    {
                      !page.detail.fields.empty("PROFILE_SUBHEADING_TEXT") &&
                      <p className="font-bold text-sta-secondary text-base md:text-2xl">
                        {page.detail.fields.textVal("PROFILE_SUBHEADING_TEXT")}
                      </p>
                    }
                  </div>
                  <div className="flex flex-col gap-2 md:gap-4">
                    <Viewer full={profile.opened} height="250px">
                      <div className="grid grid-cols-12 gap-8 xl:gap-16 3xl:gap-32">
                        <div className="col-span-12 lg:col-span-6">
                          <div className="html-viewer flex flex-col gap-2 text-sm md:text-lg text-black"
                            dangerouslySetInnerHTML={{
                              __html: page.detail.fields.textVal(
                                "PROFILE_CONTENT_TEXT",
                                `
                          <h3 style="line-height: 2;"><strong><span style="color: #fbb03b;">We are the voice that safeguards the rights, interests and welfare of the local logistics community.</span></strong></h3>
                          <p>
                            The <span className="font-bold">Singapore Transport Association (STA)</span> follows an initiative to represent the land transport industry. Since 1937, our goal to this day continues to be one of forging trade ties and generating business opportunities, as well as an entity to seek benefits and raise concerns with the government.
                          </p>
                          <p>
                            We work closely with our members, leveraging our scale in operating networks, outreach and extensive initiatives and experiences to lead and shape the transport industry.
                          </p>
                          <p>
                            STA is one of the longest Transportation Association in Singapore established in 1937. STA strive innovative collaboration with agencies and has vast hauliers in various specialist in Logistics and Transportation sector house 150 SME’s.
                          </p>
                          <p>
                            STA is uniquely positioned to work with all levels of government to provide industry insights on key transit policy issues and serving logistics communities.
                          </p>`
                              )
                            }}>

                          </div>
                        </div>
                        <div className="col-span-12 lg:col-span-6 flex justify-center items-center">
                          <img alt="member" src={page.detail.fields.tryTextVal("PROFILE_IMAGE_SRC", `/image/sta-team.jpg`)}
                            className="rounded-[200px] lg:rounded-[350px] border-[15px] 2xl:border-[25px] border-sta-primary" />
                        </div>
                      </div>
                    </Viewer>
                    <div className="flex md:hidden justify-end items-center">
                      <Button appendClassNames="uppercase"
                        onClick={() => {
                          setProfile((prevState) => {
                            return {
                              ...prevState,
                              opened: !prevState.opened
                            }
                          })
                        }}>
                        {
                          profile.opened ? "Read Less" : "Read More"
                        }
                      </Button>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </>
        }

        <div id="commitment" className="scroll-my-28">
          {
            page.detail.fields.boolVal("COMMITMENT_VISIBILITY") &&
            <div className="bg-sta-navy py-10 md:py-20">
              <Container size="md">
                <div className="grid grid-cols-12 gap-8 xl:gap-16 auto-rows-fr">
                  {
                    commitment.items.map((item, i: number) => {
                      return (
                        <React.Fragment key={`commitment-item-${i}`}>
                          <div className="col-span-12 xl:col-span-4">
                            <div className="flex flex-col gap-5 xl:gap-12">
                              <div className="relative w-full h-full">
                                <p className="font-bold text-sta-primary text-6xl xl:text-9xl">
                                  {item.order}
                                </p>
                                <p className="flex justify-center items-center absolute left-7 xl:left-14 top-[60%] bottom-[40%] font-bold text-sta-secondary text-xl xl:text-4xl">
                                  {item.name}
                                </p>
                              </div>
                              <div className={"html-viewer text-white text-base xl:text-lg"}
                                dangerouslySetInnerHTML={{ __html: item.description }}>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </Container>
            </div>
          }
        </div>
      </div>

      <div ref={navItems[1].ref} id="activity" className="scroll-my-28">
        {
          page.detail.fields.boolVal("ACTIVITY_VISIBILITY") &&
          <div className="bg-white py-10 xl:py-20">
            <Container size="md">
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4 md:gap-8">
                  <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                    {page.detail.fields.textVal("ACTIVITY_HEADING_TEXT", `What We Do`)}
                  </p>
                  {
                    !page.detail.fields.empty("ACTIVITY_SUBHEADING_TEXT") &&
                    <p className="font-bold text-sta-secondary text-base md:text-2xl">
                      {page.detail.fields.textVal("ACTIVITY_SUBHEADING_TEXT")}
                    </p>
                  }
                </div>
                <div className="block md:hidden">
                  <Carousel
                    className="grid grid-flow-col auto-rows-fr justify-start items-center"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1280: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1536: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                    navPosition="tr"
                    autoplay grabable navigable>
                    {{
                      items: activity.items.map((item) => {
                        return {
                          children: <>
                            <div className="w-full h-full min-w-[300px] min-h-[250px] md:min-h-[350px] bg-sta-navy rounded-3xl p-8">
                              <div className="flex flex-col gap-6 2xl:gap-12">
                                <div className="w-12 h-12 flex justify-center items-center rounded-full bg-sta-secondary text-sta-navy">
                                  <img alt="activity" src={item.icon} className="w-8 h-8" />
                                </div>
                                <p className="font-bold text-sta-secondary text-2xl">
                                  {item.name}
                                </p>
                                <div className="html-viewer text-lg text-white"
                                  dangerouslySetInnerHTML={{ __html: item.description }}>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      })
                    }}
                  </Carousel>
                </div>
                <div className="hidden md:grid grid-cols-12 gap-4 2xl:gap-6 auto-rows-fr">
                  {
                    activity.items.map((item, i: number) => {
                      return (
                        <React.Fragment key={`m-activity-item-${i}`}>
                          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                            <div className="w-full h-full min-h-[250px] md:min-h-[350px] bg-sta-navy rounded-3xl p-8">
                              <div className="flex flex-col gap-4 md:gap-8 xl:gap-12">
                                <div className="w-12 h-12 flex justify-center items-center rounded-full bg-sta-secondary text-sta-navy">
                                  <img alt="activity" src={item.icon} className="w-8 h-8" />
                                </div>
                                <div className="flex flex-col gap-2 md:gap-4">
                                  <p className="font-bold text-sta-secondary text-2xl">
                                    {item.name}
                                  </p>
                                  <div className="html-viewer text-lg text-white"
                                    dangerouslySetInnerHTML={{ __html: item.description }}>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </div>
            </Container>
          </div>
        }
      </div>

      <div ref={navItems[2].ref} id="milestone" className="scroll-my-28">
        {
          page.detail.fields.boolVal("MILESTONE_VISIBILITY") &&
          <div className="bg-sta-cloud pt-10 xl:pt-20 pb-5 xl:pb-10">
            <Container size="md">
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4 md:gap-8">
                  <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                    {page.detail.fields.textVal("MILESTONE_HEADING_TEXT", `Our milestone`)}
                  </p>
                  {
                    !page.detail.fields.empty("MILESTONE_SUBHEADING_TEXT") &&
                    <p className="font-bold text-sta-secondary text-base md:text-2xl">
                      {page.detail.fields.textVal("MILESTONE_SUBHEADING_TEXT", `In the heart of Singapore's trade and transport industry, a legacy was born.`)}
                    </p>
                  }
                </div>
                <Timeline className="px-4">
                  {{
                    items: milestone.items.map((item) => {
                      return {
                        heading: item.year,
                        content: <div className="html-viewer" dangerouslySetInnerHTML={{ __html: item.description }} />
                      }
                    })
                  }}
                </Timeline>
              </div>
            </Container>
          </div>
        }

        <div id="gallery" className="scroll-my-28">
          {
            page.detail.fields.boolVal("GALLERY_VISIBILITY") && gallery.items.length > 0 &&
            <div className="bg-sta-cloud pt-5 xl:pt-10 pb-10 xl:pb-20">
              <Container size="md">
                <Carousel
                  className="grid grid-flow-col auto-rows-fr justify-start items-center"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1280: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1536: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  variant="secondary"
                  navPosition='tr'
                  autoplay
                  grabable
                  navigable>
                  {{
                    items: gallery.items.map((item) => {
                      return {
                        children: <>
                          <div className="w-full h-full min-w-[300px] min-h-[250px] md:min-h-[350px] rounded-3xl p-1">
                            <div className="flex flex-col gap-4">
                              <img alt="gallery" src={item.thumbnail}
                                className="aspect-video rounded-2xl shadow-xl" />
                              <div className="flex flex-col gap-1">
                                <p className="font-bold text-sta-primary text-2xl">
                                  {item.title}
                                </p>
                                <div className="html-viewer text-lg text-black"
                                  dangerouslySetInnerHTML={{ __html: item.description }}>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    })
                  }}
                </Carousel>
              </Container>
            </div>
          }
        </div>
      </div>

      <div ref={navItems[3].ref} id="partnership" className="scroll-my-28">
        {
          page.detail.fields.boolVal("PARTNER_VISIBILITY") &&
          <div className="bg-sta-navy py-10 xl:py-20">
            <Container size="md">
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4 md:gap-8">
                  <p className="w-fit font-bold text-white text-xl md:text-4xl border-b-4 border-sta-secondary">
                    {page.detail.fields.textVal("PARTNER_HEADING_TEXT", `Our Partners`)}
                  </p>
                  {
                    !page.detail.fields.empty("PARTNER_SUBHEADING_TEXT") &&
                    <p className="font-bold text-sta-secondary text-base md:text-2xl">
                      {page.detail.fields.textVal("PARTNER_SUBHEADING_TEXT")}
                    </p>
                  }
                </div>
                <Viewer full={partner.opened} height="250px">
                  <div className="grid grid-cols-12 gap-4 sm:gap-8 auto-rows-fr">
                    {
                      partner.items.map((item, i: number) => {
                        return (
                          <React.Fragment key={`partner-item-${i}`}>
                            <div className="col-span-6 lg:col-span-3">
                              <img alt="partner" src={item.logo}
                                className="w-full aspect-square object-contain object-center rounded-2xl shadow-xl" />
                            </div>
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                </Viewer>
                <div className="flex md:hidden justify-end items-center">
                  <Button appendClassNames="uppercase"
                    onClick={() => {
                      setPartner((prevState) => {
                        return {
                          ...prevState,
                          opened: !prevState.opened
                        }
                      })
                    }}>
                    {
                      partner.opened ? "View Less" : "View More"
                    }
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        }
      </div>

      <NewsletterSection />
    </>
  )
}