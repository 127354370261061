import * as React from "react"
import ReactGA from "react-ga4"
import { useNavigate } from "react-router-dom"
import { Badge } from "../components/Badge"
import { ButtonLink } from "../components/Button"
import { Carousel } from "../components/Carousel"
import { Container } from "../components/Container"
import { Skeleton } from "../components/Skeleton"
import { HeaderContext } from "../components/Header"
import { Link } from "../components/Router"
import { Meta } from "../components/Meta"
import { Modal } from "../components/Modal"
import { Carousel as BannerCarousel } from "../features/Banner"
import { EmptyEvent } from "../features/Event"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, useHashFragment, usePage } from "../hooks"
import { dateFormat } from "../typeconv"
import { isSameDay } from "../typecmp"
import { Blog as BlogService } from "../services/api/Blog"

const blogService = new BlogService({
  host: process.env.REACT_APP_API_HOST
})
const articleDefaultThumbnail = "/image/placeholder-1080x720.png"
const eventDefaultThumbnail = "/image/placeholder-1280x720.png"
const adsSquareDefaultThumbnail = "/image/placeholder-1080x1080.png"
const adsVideoDefaultThumbnail = "/image/placeholder-1280x612.png"

type HeroItem = {
  headingText?: string
  subHeadingText?: string
  ctaTo?: string
  ctaText?: string
  bgImage?: string
  position: string
  id: string
}

type ArticleItem = {
  slug: string
  title: string
  mode: string
  url?: string
  content?: string
  thumbnailUrl?: string
  createdAt: Date
}

type AdvertisementItem = {
  id: string
  title: string
  mode: string
  content?: string
  url?: string
  thumbnailUrl?: string
}

type PartnerItem = {
  order: string
  description: string
}

type EventItem = {
  title: string
  content?: string
  thumbnailUrl?: string
  venue?: string
  fees?: string
  registrationLink?: string
  createdAt: Date
  startedAt?: Date
  finishedAt?: Date
}

type EventState = {
  loading: boolean
  items: EventItem[]
  totalItems: number
  opened: boolean
}

export default function Home(): React.JSX.Element {
  const [page] = usePage({
    slug: "home"
  })
  const [heroes, setHeroes] = React.useState({
    loading: false,
    items: [] as HeroItem[],
    totalItems: 0,
  })
  const [articles, setArticles] = React.useState({
    loading: false,
    items: [] as ArticleItem[],
    totalItems: 0,
    opened: false,
  })
  const [ongoingEvents, setOngoingEvents] = React.useState({
    loading: false,
    items: [] as EventItem[],
    totalItems: 0,
    opened: false,
  } as EventState)
  const [upcomingEvents, setUpcomingEvents] = React.useState({
    loading: false,
    items: [] as EventItem[],
    totalItems: 0,
    opened: false,
  } as EventState)
  const [ads, setAds] = React.useState({
    loading: false,
    items: [] as AdvertisementItem[],
    totalItems: 0,
    opened: false,
    detail: {} as AdvertisementItem,
  })
  const [partner, setPartner] = React.useState({
    loading: false,
    items: [] as PartnerItem[],
  })
  const hero = React.useRef<HTMLDivElement>()
  const [, setHeader] = React.useContext(HeaderContext)
  const navigate = useNavigate()

  async function searchEvents(setter: React.Dispatch<React.SetStateAction<EventState>>, filter: Record<string, any>) {
    setter((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const searchEvents = await blogService.SearchEvent(filter)
    setter((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (searchEvents.error) {
      return
    }

    setter((prevState) => {
      return {
        ...prevState,
        items: searchEvents.data.items,
        totalItems: searchEvents.data.summary.total_items
      }
    })
  }

  async function searchArticles(filter?: Record<string, any>) {
    setArticles((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const searchArticles = await blogService.SearchArticle(filter)
    setArticles((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (searchArticles.error) {
      return
    }

    setArticles((prevState) => {
      return {
        ...prevState,
        items: searchArticles.data.items,
        totalItems: searchArticles.data.summary.total_items
      }
    })
  }

  function openAds(ads: AdvertisementItem) {
    if (ads.mode === "url") {
      return
    }

    setAds((prevState) => {
      return {
        ...prevState,
        opened: true,
        detail: ads
      }
    })
  }

  const loadingArticles = [1].map((_) => {
    return {
      children: <>
        <CardArticle loading={articles.loading} />
      </>
    }
  })

  const handleEventClick = () => {
    navigate("/news-events")
  }

  React.useEffect(() => {
    Promise.all([
      searchArticles({
        page: 1,
        totalItems: 3
      }),
      searchEvents(setOngoingEvents, {
        page: 1,
        totalItems: 5,
        mode: "ongoing",
      }),
      searchEvents(setUpcomingEvents, {
        page: 1,
        totalItems: 5,
        mode: "upcoming",
      }),
    ])
  }, [])

  React.useEffect(() => {
    if (page.loading) {
      setHeroes((prevState) => {
        return {
          ...prevState,
          loading: true,
        }
      })
      setAds((prevState) => {
        return {
          ...prevState,
          loading: true,
        }
      })
      setPartner((prevState) => {
        return {
          ...prevState,
          loading: true,
        }
      })
      return
    }

    const heroItems = page.detail.fields.collectionVal("HERO_ITEMS")
    const adsItems = page.detail.fields.collectionVal("ADS_ITEMS")
    const partnerItems = page.detail.fields.collectionVal("PARTNER_BENEFIT_ITEMS")

    setHeroes((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: heroItems.map((hero) => {
          return {
            headingText: hero.textVal("heading_text", ""),
            subHeadingText: hero.textVal("subheading_text", ""),
            ctaTo: hero.textVal("cta_to", ""),
            ctaText: hero.textVal("cta_text", ""),
            bgImage: hero.tryTextVal("bg_image_src", "/image/hero-shipping.jpg"),
            position: hero.textVal("position", "center"),
            id: hero.textVal("id", ""),
          }
        }),
        totalItems: heroItems.length
      }
    })
    setAds((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: adsItems.map((ads) => {
          return {
            title: ads.textVal("title", ""),
            mode: ads.textVal("mode", "content"),
            content: ads.textVal("content"),
            url: ads.textVal("url"),
            thumbnailUrl: ads.tryTextVal("thumbnail"),
            id: ads.textVal("id", ""),
          }
        }),
        totalItems: adsItems.length
      }
    })
    setPartner((prevState) => {
      return {
        ...prevState,
        loading: false,
        items: partnerItems.map((partner) => {
          return {
            order: partner.textVal("order", ""),
            description: partner.textVal("description", ""),
          }
        })
      }
    })

    const showHero = page.detail.fields.boolVal("HERO_VISIBILITY") && heroItems.length > 0
    if (!showHero) {
      setHeader((prevState) => {
        const meta = prevState.meta
        meta.set("sticky", "true")
        meta.set("light", "true")

        return {
          ...prevState,
          meta,
        }
      })
    } else {
      setHeader((prevState) => {
        const meta = prevState.meta
        meta.delete("sticky")
        meta.delete("light")

        return {
          ...prevState,
          meta,
        }
      })
    }
  }, [page, setHeader])

  React.useEffect(() => {
    if (!hero && !hero.current) {
      return
    }

    setHeader((prevState) => {
      return {
        ...prevState,
        intersector: hero
      }
    })

    return () => {
      setHeader((prevState) => {
        return {
          ...prevState,
          intersector: undefined
        }
      })
    }
  }, [setHeader])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "Home"}`,
          description: `A pioneering entity dedicated to representing the dynamic land transport industry.`,
        }}
      </Meta>

      <div> {/*  @note: do not remove to avoid javascript modal not iresponsiveness */}
        {
          (page.detail.fields.boolVal("HERO_VISIBILITY") && heroes.items.length > 0) &&
          <>
            <div></div> {/* @note: do not remove to avoid newlseter section contain below element attributes */}
            <div ref={hero}>
              <BannerCarousel
                className="grid grid-flow-col auto-rows-fr justify-start items-center"
                variant="primary"
                navPosition="mid"
                slidesPerView={1}
                spaceBetween={0}
                autoplayDelay={6000}
                autoplay
                grabable paginable navigable
                onLoad={() => {
                  if (heroes.items.length === 0) {
                    return
                  }

                  ReactGA.event("hero_view", {
                    content_group: "carousel",
                    content_type: "hero",
                    content_id: heroes.items[0].id,
                  })
                }}
                onPageSelect={({ page }) => {
                  ReactGA.event("hero_view", {
                    content_group: "carousel",
                    content_type: "hero",
                    content_id: heroes.items[page].id,
                  })
                }}>
                {{
                  items: heroes.items.map((item) => {
                    return {
                      children: <>
                        <HeroSection {...item} />
                      </>
                    }
                  })
                }}
              </BannerCarousel>
            </div>
          </>
        }

        {
          (page.loading || page.detail.fields.boolVal("INFO_VISIBILITY")) &&
          <div className="bg-white py-10 md:py-20">
            <Container size="md">
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4">
                  <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                    {page.detail.fields.textVal("INFO_HEADING_TEXT", `What's on?`)}
                  </p>
                  {
                    !page.detail.fields.empty("INFO_SUBHEADING_TEXT") &&
                    <p className="text-black text-base md:text-xl">
                      {page.detail.fields.textVal("INFO_SUBHEADING_TEXT", `Latest news and events`)}
                    </p>
                  }
                </div>

                {
                  articles.loading &&
                  <CardArticle loading={articles.loading} />
                }
                {
                  !articles.loading &&
                  <Carousel
                    className="grid grid-flow-col auto-rows-fr justify-start items-center"
                    variant="secondary"
                    navPosition="tr"
                    slidesPerView={1}
                    spaceBetween={20}
                    autoplay
                    autoplayDelay={3500}
                    grabable navigable>
                    {{
                      items: articles.loading ?
                        loadingArticles :
                        articles.items.map((item) => {
                          return {
                            children: <>
                              <Link to={(item.mode === "url" ? item.url : `/article/${item.slug}`)}
                                target={(item.mode === "url" ? "_blank" : "_self")}
                                hideCrawl={item.mode === "url"}>
                                <CardArticle
                                  item={item}
                                  loading={articles.loading} />
                              </Link>
                            </>
                          }
                        })
                    }}
                  </Carousel>
                }

                {
                  !ongoingEvents.loading && ongoingEvents.items.length === 0 &&
                    !upcomingEvents.loading && upcomingEvents.items.length === 0 ?
                    <EmptyEvent description="Stay tuned for our upcoming events!" />
                    :
                    <div className="grid grid-cols-12 gap-4 md:gap-8">
                      {
                        ongoingEvents.items.length > 0 &&
                        <div className="col-span-12 lg:col-span-6">
                          <Carousel
                            className="grid grid-flow-col auto-rows-fr justify-start items-center"
                            variant="info"
                            navPosition='mid'
                            slidesPerView={1}
                            spaceBetween={35}
                            autoplay grabable navigable>
                            {{
                              items: ongoingEvents.loading ?
                                [
                                  {
                                    children: <>
                                      <CardEvent loading={true} />
                                    </>
                                  }
                                ] :
                                ongoingEvents.items.map((item) => {
                                  return {
                                    children: <>
                                      <CardEvent
                                        onClick={handleEventClick}
                                        badge={<>
                                          <span className="uppercase">
                                            Ongoing Events / Programmes
                                          </span>
                                        </>}
                                        item={item}
                                        loading={ongoingEvents.loading} />
                                    </>
                                  }
                                })
                            }}
                          </Carousel>
                        </div>
                      }

                      {
                        upcomingEvents.items.length > 0 &&
                        <div className="col-span-12 lg:col-span-6">
                          <Carousel
                            className="grid grid-flow-col auto-rows-fr justify-start items-center"
                            variant="info"
                            navPosition='mid'
                            slidesPerView={1}
                            spaceBetween={35}
                            autoplay grabable navigable>
                            {{
                              items: upcomingEvents.loading ?
                                [
                                  {
                                    children: <>
                                      <CardEvent loading={true} />
                                    </>
                                  }
                                ] :
                                upcomingEvents.items.map((item) => {
                                  return {
                                    children: <>
                                      <CardEvent
                                        onClick={handleEventClick}
                                        badge={<>
                                          <span className="uppercase">
                                            Upcoming Events
                                          </span>
                                        </>}
                                        item={item}
                                        loading={upcomingEvents.loading} />
                                    </>
                                  }
                                })
                            }}
                          </Carousel>
                        </div>
                      }
                    </div>
                }
              </div>
            </Container>
          </div>
        }

        {
          (page.loading || page.detail.fields.boolVal("ADS_VISIBILITY")) && (ads.items.length > 0) &&
          <div className="bg-sta-cloud py-10 md:py-20">
            <Container size="md">
              <div className="flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-4">
                  <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                    {page.detail.fields.textVal("ADS_HEADING_TEXT", `Advertisement`)}
                  </p>
                  {
                    !page.detail.fields.empty("ADS_SUBHEADING_TEXT") &&
                    <p className="text-black text-base md:text-xl">
                      {page.detail.fields.textVal("ADS_SUBHEADING_TEXT")}
                    </p>
                  }
                </div>

                {
                  ads.loading &&
                  <div className="grid grid-cols-12 auto-rows-fr gap-6 2xl:gap-9">
                    {
                      [1, 2, 3].map((_, i: number) => {
                        return (
                          <React.Fragment key={`skeleton-ads-item-${i}`}>
                            <div className={(i === 0 ? "col-span-12 lg:col-span-6" : "col-span-6 lg:col-span-3")}>
                              <CardAds
                                loading={ads.loading}
                                square={i > 0}
                                badge={<>
                                  <span className="uppercase">
                                    Ads
                                  </span>
                                </>} />
                            </div>
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                }

                {
                  !ads.loading && ads.items.length > 0 &&
                  <div className="grid grid-cols-12 auto-rows-fr gap-6 2xl:gap-9">
                    {
                      ads.items.map((item, i: number) => {
                        const cardAds = <>
                          <CardAds
                            loading={ads.loading}
                            square={i > 0}
                            item={item}
                            badge={<>
                              <span className="uppercase">
                                Ads
                              </span>
                            </>}
                            onClick={() => {
                              openAds(item)

                              ReactGA.event("ads_click", {
                                content_group: "card",
                                content_type: "ads",
                                content_id: item.id,
                                link_url: item.url,
                              })
                            }} />
                        </>

                        return (
                          <React.Fragment key={`ads-item-${i}`}>
                            <div className={(i === 0 ? "col-span-12 lg:col-span-6" : "col-span-6 lg:col-span-3")}>
                              {
                                item.mode === "url" ?
                                  <Link to={item.url} target="_blank" hideCrawl>
                                    {cardAds}
                                  </Link>
                                  :
                                  cardAds
                              }
                            </div>
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                }
              </div>
            </Container>
          </div>
        }

        {
          (page.loading || page.detail.fields.boolVal("BANNER_VISIBILITY")) && (ads.items.length > 0) &&
          <div className="relative bg-cover bg-center"
            style={{ backgroundImage: `url(${page.detail.fields.tryTextVal("BANNER_BG_IMAGE_SRC", `/image/banner-shipping.jpg`)})` }}>
            <div className={
              "bg-[linear-gradient(89.99deg,_#0174BA_7.12%,_rgba(1,_116,_186,_0.588235)_46.12%,_rgba(1,_116,_186,_0)_64.23%)] " +
              "min-h-[500px] lg:min-h-[650px] flex justify-start items-end md:items-center"
            }>
              <Container size="md" appendClassNames="py-4">
                <div className="md:w-1/2 flex flex-col gap-5 md:gap-10 z-[1]">
                  <p className="font-bold text-4xl md:text-6xl text-white [text-shadow:4px_4px_5px_rgba(0,_0,_0,_0.25)]">
                    {page.detail.fields.textVal("BANNER_HEADING_TEXT", `Singapore Transport Association`)}
                  </p>
                  {
                    !page.detail.fields.empty("BANNER_SUBHEADING_TEXT") &&
                    <p className="text-lg md:text-2xl text-white [text-shadow:4px_4px_5px_rgba(0,_0,_0,_0.25)]">
                      {
                        page.detail.fields.textVal(
                          "BANNER_SUBHEADING_TEXT",
                          `A pioneering entity dedicated to representing the dynamic land transport industry`
                        )
                      }
                    </p>
                  }
                  {
                    !page.detail.fields.empty("BANNER_CTA_TO") && !page.detail.fields.empty("BANNER_CTA_TEXT") &&
                    <div>
                      <ButtonLink to={page.detail.fields.textVal("BANNER_CTA_TO", `/about-us`)} variant="secondary"
                        onClick={() => {
                          ReactGA.event("banner_cta_click", {
                            content_group: "section",
                            content_type: "banner",
                            link_url: page.detail.fields.textVal("BANNER_CTA_TO", `/about-us`),
                          })
                        }}>
                        <span className="uppercase">
                          {page.detail.fields.textVal("BANNER_CTA_TEXT", `Learn More About Us`)}
                        </span>
                      </ButtonLink>
                    </div>
                  }
                </div>
              </Container>
            </div>
          </div>
        }

        {
          (page.loading || page.detail.fields.boolVal("PARTNER_VISIBILITY")) &&
          <div className="bg-sta-navy py-10 md:py-20">
            <Container size="md">
              <div className="grid grid-cols-12 gap-4 md:gap-8">
                <div className="col-span-12 md:col-span-6">
                  <div className="flex flex-col gap-4">
                    <p className="w-fit font-bold text-white text-xl md:text-4xl border-b-4 border-sta-secondary">
                      {page.detail.fields.textVal("PARTNER_HEADING_TEXT", `Be Our Partner`)}
                    </p>
                    {
                      !page.detail.fields.empty("PARTNER_SUBHEADING_TEXT") &&
                      <p className="text-white text-base md:text-xl">
                        {page.detail.fields.textVal("PARTNER_SUBHEADING_TEXT", `Kickstart a partnership with us`)}
                      </p>
                    }
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="flex flex-col gap-6 md:gap-12">
                    <ul className="list-none flex flex-col gap-3 md:gap-6">
                      {
                        partner.loading &&
                        <>
                          <li className="flex flex-row justify-start items-center gap-3 md:gap-6">
                            <Skeleton width="10%" height="10px" rounded />
                            <Skeleton width="90%" height="10px" rounded />
                          </li>
                          <li className="flex flex-row justify-start items-center gap-3 md:gap-6">
                            <Skeleton width="10%" height="10px" rounded />
                            <Skeleton width="90%" height="10px" rounded />
                          </li>
                        </>
                      }
                      {
                        !partner.loading && partner.items.length > 0 &&
                        <>
                          {
                            partner.items.map((item, i: number) => {
                              return (
                                <React.Fragment key={`benefit-item-${i}`}>
                                  <li className="flex flex-row justify-start items-center gap-3 md:gap-6">
                                    <span className="font-bold text-2xl md:text-4xl text-sta-secondary">
                                      {item.order}
                                    </span>
                                    <p className="text-white text-base md:-text-lg">
                                      {item.description}
                                    </p>
                                  </li>
                                </React.Fragment>
                              )
                            })
                          }
                        </>
                      }
                    </ul>
                    <div>
                      <ButtonLink variant="primary"
                        to={page.detail.fields.tryTextVal("PARTNER_CTA_TO", `/contact-us`)}>
                        <span className="uppercase">
                          {
                            page.detail.fields.textVal(
                              "PARTNER_CTA_TEXT",
                              `Be Our Partner`
                            )
                          }
                        </span>
                      </ButtonLink>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        }

        <NewsletterSection />
      </div>

      <Modal portalId="overlay-root"
        size="2xl" opened={ads.opened}
        onClose={() => {
          setAds((prevState) => {
            return {
              ...prevState,
              opened: false,
            }
          })
        }}
        lostable
        closeable>
        {{
          content: <>
            <div className="h-full max-h-[500px] overflow-y-auto">
              <div className="grid grid-cols-12 gap-4 md:gap-8">
                <div className="col-span-12 lg:col-span-4">
                  <img alt="logo" className="aspect-square object-cover object-center w-full"
                    src={ads.detail.thumbnailUrl || adsSquareDefaultThumbnail} />
                </div>
                <div className="col-span-12 lg:col-span-8">
                  <div className="flex flex-col gap-2 justify-center items-start">
                    <p className="font-bold text-xl md:text-4xl text-sta-primary">
                      {ads.detail.title}
                    </p>
                    {
                      ads.detail.content &&
                      <div className="html-viewer text-base md:text-xl"
                        dangerouslySetInnerHTML={{ __html: ads.detail.content }} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        }}
      </Modal>
    </>
  )
}

type HeroSectionProps = {
  headingText?: string
  subHeadingText?: string
  ctaTo?: string
  ctaText?: string
  bgImage?: string
  position: string
  id: string
}

function HeroSection(props: HeroSectionProps): React.JSX.Element {
  const {
    position,
    bgImage,
    ctaText,
    ctaTo,
    headingText,
    subHeadingText,
    id
  } = props

  return (
    <>
      {
        position === "bottom" &&
        <div className="relative bg-cover bg-center"
          style={{ backgroundImage: `url(${bgImage})` }}>
          <div className={
            "bg-[linear-gradient(180deg,_rgba(0,_0,_0,_0.7)_-0.52%,_rgba(0,_0,_0,_0)_19.66%,_rgba(0,_41,_96,_0)_21.07%,_#002960_78.28%)] " +
            "h-full min-h-[600px] lg:h-[calc(100vh_-_60px)] max-h-[800px] flex justify-center items-center"
          }>
            <Container size="md" appendClassNames="py-4">
              <div className="w-full h-[450px] px-5 sm:px-12 flex justify-start items-end">
                <div className="w-full flex flex-col xl:flex-row gap-x-5 gap-y-10 md:gap-y-14 justify-between items-start xl:items-end z-[1]">
                  <div className="w-full basis-8/12 flex flex-col gap-5">
                    <p className="font-bold text-4xl md:text-5xl 3xl:text-6xl text-white [text-shadow:4px_4px_5px_rgba(0,_0,_0,_0.25)]">
                      {headingText}
                    </p>
                    {
                      subHeadingText &&
                      <p className="text-lg md:text-xl 3xl:text-2xl text-white [text-shadow:4px_4px_5px_rgba(0,_0,_0,_0.25)]">
                        {subHeadingText}
                      </p>
                    }
                  </div>
                  {
                    ctaTo && ctaText &&
                    <div className="flex justify-end basis-4/12">
                      <ButtonLink to={ctaTo} variant="secondary"
                        onClick={() => {
                          ReactGA.event("hero_cta_click", {
                            content_group: "carousel",
                            content_type: "hero",
                            content_id: id,
                            link_url: ctaTo,
                          })
                        }}>
                        <span className="uppercase">
                          {ctaText}
                        </span>
                      </ButtonLink>
                    </div>
                  }
                </div>
              </div>
            </Container>
          </div>
        </div>
      }

      {
        position === "center" &&
        <div className="relative bg-cover bg-center"
          style={{ backgroundImage: `url(${bgImage})` }}>
          <div className={
            "bg-[linear-gradient(0.04deg,_rgba(0,_41,_96,_0)_14.21%,_rgba(0,_41,_96,_0.526739)_39.33%,_rgba(0,_41,_96,_0.95)_81.19%,_#002960_88.26%)] " +
            "h-full min-h-[600px] lg:h-[calc(100vh_-_60px)] max-h-[800px] flex justify-center items-center"
          }>
            <Container size="md" appendClassNames="py-4">
              <div className="w-full flex justify-center items-center">
                <div className="w-full max-w-[300px] sm:max-w-[500px] xl:max-w-[750px] flex flex-col justify-center items-center gap-x-5 gap-y-10 md:gap-y-14 z-[1]">
                  <div className="flex flex-col gap-5">
                    <p className="font-bold text-4xl md:text-5xl 3xl:text-6xl text-center text-white [text-shadow:4px_4px_5px_rgba(0,_0,_0,_0.25)]">
                      {headingText}
                    </p>
                    {
                      subHeadingText &&
                      <p className="text-lg md:text-xl 3xl:text-2xl text-center text-white [text-shadow:4px_4px_5px_rgba(0,_0,_0,_0.25)]">
                        {subHeadingText}
                      </p>
                    }
                  </div>
                  {
                    ctaTo && ctaText &&
                    <div>
                      <ButtonLink to={ctaTo} variant="secondary"
                        onClick={() => {
                          ReactGA.event("hero_cta_click", {
                            content_group: "carousel",
                            content_type: "hero",
                            content_id: id,
                            link_url: ctaTo,
                          })
                        }}>
                        <span className="uppercase">
                          {ctaText}
                        </span>
                      </ButtonLink>
                    </div>
                  }
                </div>
              </div>
            </Container>
          </div>
        </div>
      }
    </>
  )
}

type CardArticleProps = {
  children?: React.ReactNode
  loading?: boolean
  item?: ArticleItem
  onClick?: () => void
}

function CardArticle(props: CardArticleProps): React.JSX.Element {
  const {
    loading = false,
    item,
    onClick = () => { },
  } = props

  const cursor = (loading ? "cursor-wait" : "cursor-pointer")

  return (
    <div className={"relative w-full h-full " + cursor} onClick={onClick}>
      {
        !loading &&
        <Badge variant="secondary" appendClassNames="absolute top-[10px] left-[10px]">
          <span className="uppercase">
            Latest News
          </span>
        </Badge>
      }
      <div className="w-full h-full grid grid-cols-12">
        <div className="col-span-12 lg:col-span-5 h-full lg:h-fit w-fullt aspect-video">
          {
            loading ?
              <Skeleton width="100%" height="100%" appendClassNames="rounded-t-3xl lg:rounded-tr-none lg:rounded-l-3xl shadow-xl" />
              :
              <img alt="thumbnail" src={item.thumbnailUrl || articleDefaultThumbnail}
                className="w-full h-full object-cover object-center object-no-repeat rounded-t-3xl lg:rounded-tr-none lg:rounded-l-3xl shadow-xl" />
          }
        </div>
        <div className="col-span-12 lg:col-span-7">
          <div className="h-full p-4 md:p-7 rounded-b-3xl lg:rounded-bl-none lg:rounded-r-3xl bg-sta-navy shadow-xl">
            <div className="h-full flex flex-col gap-4 justify-center items-start">
              {
                loading ?
                  <>
                    <Skeleton width="100%" height="20px" rounded />
                    <div className="w-full flex flex-col gap-2">
                      <Skeleton width="90%" height="10px" rounded />
                      <Skeleton width="90%" height="10px" rounded />
                      <Skeleton width="90%" height="10px" rounded />
                      <Skeleton width="90%" height="10px" rounded />
                    </div>
                  </>
                  :
                  <>
                    <p className="font-bold text-white text-base md:text-2xl">
                      {item.title}
                    </p>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type CardEventProps = {
  children?: React.ReactNode
  loading?: boolean
  item?: EventItem
  badge?: React.ReactNode
  onClick?: () => void
}

function CardEvent(props: CardEventProps): React.JSX.Element {
  const {
    loading = false,
    item,
    badge,
    onClick = () => { },
  } = props

  const cursor = (loading ? "cursor-wait" : "cursor-pointer")

  return (
    <div className={"relative w-full h-full " + cursor} onClick={onClick}>
      {
        !loading && badge &&
        <Badge variant="secondary" appendClassNames="absolute top-[10px] left-[10px]">
          {badge}
        </Badge>
      }
      <div className="h-full bg-sta-navy rounded-3xl shadow-lg">
        <div className="w-full md:max-h-full lg:min-h-[300px] xl:min-h-[350px] 3xl:min-h-[400px] bg-white rounded-t-3xl aspect-video">
          {
            loading ?
              <Skeleton appendClassNames="w-full h-full rounded-t-3xl" />
              :
              <img className="w-full h-full object-cover object-center rounded-t-3xl"
                alt="thumbnail" src={item.thumbnailUrl || eventDefaultThumbnail} />
          }
        </div>
        <div className="w-full rounded-b-3xl">
          <div className="p-4 md:p-7 flex flex-row gap-4 justify-center items-center">
            <div className="basis-2/12 flex flex-col gap-2 justify-center items-center">
              {
                loading ?
                  <>
                    <Skeleton width="100%" height="100%" rounded />
                    <Skeleton width="100%" height="100%" rounded />
                  </>
                  :
                  <>
                    {
                      item.startedAt &&
                      <>
                        <span className="text-sta-secondary text-xs md:text-base">
                          {dateFormat(item.startedAt, 'MMM')}
                        </span>
                        <span className="font-bold text-sta-secondary text-2xl md:text-4xl">
                          {dateFormat(item.startedAt, 'D')}
                        </span>
                      </>
                    }
                  </>
              }
            </div>
            <div className="basis-10/12 flex flex-col gap-2">
              {
                loading ?
                  <>
                    <Skeleton width="70%" height="10px" rounded />
                    <Skeleton width="90%" height="20px" rounded />
                  </>
                  :
                  <>
                    {
                      item.startedAt && item.finishedAt &&
                      <span className="font-light text-white text-xs md:text-base">
                        {
                          isSameDay(item.startedAt, item.finishedAt) ?
                            <>
                              {`${dateFormat(item.startedAt, 'h:mm a')} - ${dateFormat(item.finishedAt, 'h:mm a')}`}
                            </>
                            :
                            <>
                              {`${dateFormat(item.startedAt, 'MMM D')} - ${dateFormat(item.finishedAt, 'MMM D')}`}
                            </>
                        }
                      </span>
                    }
                    <p className="font-bold text-white text-base md:text-2xl">
                      {item.title}
                    </p>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type CardAdsProps = {
  children?: React.ReactNode
  loading?: boolean
  square?: boolean
  item?: AdvertisementItem
  badge?: React.ReactNode
  onClick?: () => void
}

function CardAds(props: CardAdsProps): React.JSX.Element {
  const {
    loading = false,
    square = false,
    item,
    badge,
    onClick = () => { },
  } = props

  const handleClick = () => {
    onClick && onClick()
  }

  const cursor = (loading ? "cursor-wait" : "cursor-pointer")
  const adsDefaultThumbnail = (square) ? adsSquareDefaultThumbnail : adsVideoDefaultThumbnail

  return (
    <div className={"relative w-full h-full " + cursor} onClick={handleClick}>
      {
        !loading && badge &&
        <Badge variant="secondary" appendClassNames="absolute top-[10px] left-[10px]">
          {badge}
        </Badge>
      }
      <div className="h-full bg-sta-primary rounded-3xl shadow-xl">
        <div className={"bg-white rounded-t-3xl w-full lg:max-h-[210px] xl:max-h-[250px] 2xl:max-h-[290px] 3xl:max-h-[330px] " + (square ? "aspect-square" : "aspect-video")}>
          {
            loading ?
              <Skeleton appendClassNames="w-full h-full rounded-t-3xl" />
              :
              <img className="w-full h-full object-cover object-center rounded-t-3xl"
                alt="thumbnail" src={item.thumbnailUrl || adsDefaultThumbnail} />
          }
        </div>
        <div className="w-full rounded-b-3xl">
          <div className="p-4 md:p-7 flex flex-col gap-4">
            {
              loading ?
                <>
                  <Skeleton width="90%" height="20px" rounded />
                  <Skeleton width="70%" height="10px" rounded />
                  <Skeleton width="70%" height="10px" rounded />
                  <Skeleton width="70%" height="10px" rounded />
                </>
                :
                <>
                  <p className="font-bold text-white text-base md:text-lg xl:text-xl 2xl:text-2xl">
                    {item.title}
                  </p>
                </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}