import * as React from "react"
import {
  PaginationPosition,
  PaginationPositionProps
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("bottom-container", "absolute bottom-[20px] md:bottom-[40px] z-[2] w-full flex flex-row flex-nowrap gap-2 lg:gap-4 justify-center items-center")
defaultTheme.set("bottom-button", "cursor-pointer rounded-full")
defaultTheme.set("bottom-button-active-true", "w-16 sm:w-24 md:w-32 h-3	bg-[rgba(237,_237,_237,_1)]")
defaultTheme.set("bottom-button-active-false", "w-3 h-3 bg-[rgba(200,_200,_200,_0.7)]")

themes.set("", defaultTheme)

class PaginationStyle {
  private theme: string = ""
  private position: string = ""

  public buildContainer(): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has(`${this.position}-container`)) {
      style += theme.get(`${this.position}-container`)
    }

    return style
  }

  public buildButton({ active }: { active: boolean }): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has(`${this.position}-button`)) {
      style += theme.get(`${this.position}-button`)
    }

    if (theme.has(`${this.position}-button-active-${active}`)) {
      style += " " + theme.get(`${this.position}-button-active-${active}`)
    }

    return style
  }

  public setTheme(theme: string): PaginationStyle {
    this.theme = theme
    return this
  }

  public setPosition(position: string): PaginationStyle {
    this.position = position
    return this
  }
}

type CarouselPaginationProps = {
  classNames?: {
    pagination?: () => string
  }
  enabled?: boolean
  activeIndex?: number
  totalItems?: number
  position?: PaginationPositionProps
  onSelect?: (p: number) => void
}

const paginationStyle = new PaginationStyle()

export function CarouselPagination(props: CarouselPaginationProps): React.JSX.Element {
  const {
    enabled = false,
    activeIndex = -1,
    totalItems = 0,
    position = PaginationPosition.BOTTOM,
    onSelect = () => { },
  } = props

  const {
    pagination: paginationFn
  } = props.classNames || {}

  function selectPage(p: number) {
    onSelect && onSelect(p)
  }

  paginationStyle.setPosition(position)

  const containerClassNames = paginationStyle.buildContainer()

  return (
    <>
      {
        enabled && position === PaginationPosition.BOTTOM &&
        <div className={(paginationFn && paginationFn()) || containerClassNames}>
          {
            Array.from(Array(totalItems).keys()).map((_, i: number) => {
              return (
                <React.Fragment key={`pagination-item-${i}`}>
                  <button className={paginationStyle.buildButton({ active: activeIndex === i })}
                    onClick={() => { selectPage(i) }}>
                  </button>
                </React.Fragment>
              )
            })
          }
        </div>
      }
    </>
  )
}