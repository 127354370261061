
type InputValue = {
  key: string
  type: string
  bool_value?: boolean
  text_value?: string
  file_value?: string
  collection_value?: InputValue[]
}

type FieldValue = {
  key: string
  type: string
  bool_value?: boolean
  text_value?: string
  file_value?: string
  collection_value?: Field[]
}

export enum FieldType {
  BOOL = "boolean",
  TEXT = "text",
  FILE = "file",
  COLLECTION = "collection",
}

export class Field {

  private fields: Map<string, FieldValue>

  constructor(values: InputValue[] = []) {
    this.fields = this.parseFields(values)
    return this
  }

  /**
   * @description 
   *  return `true` when field is available
   * @param `key` 
   * @returns `boolean`
   */
  public has(key: string): boolean {
    return this.fields.has(key)
  }

  /**
   * @description 
   *  return `true` when field is not available
   *  or available but doesn't have value according to its `type`
   * @param `key` 
   * @returns `boolean`
   */
  public empty(key: string): boolean {
    if (!this.has(key)) {
      return true
    }

    const field = this.fields.get(key)
    let empty = false

    switch (field.type) {
      case FieldType.TEXT:
        empty = this.isTextEmpty(field.text_value)
        break
      case FieldType.BOOL:
        empty = field.bool_value === undefined || field.bool_value === null
        break
      case FieldType.FILE:
        empty = this.isTextEmpty(field.file_value)
        break
      case FieldType.COLLECTION:
        empty = this.isCollectionEmpty(field.collection_value)
        break
    }

    return empty
  }

  /**
   * @description 
   *  return `boolean` when `key` exists with a `boolean` value
   *  otherwise return `false` unless `defaultValue` is specified
   * @param `key` 
   * @param `defaultValue` 
   * @returns 
   */
  public boolVal(key: string, defaultValue: boolean = false): boolean {
    if (this.empty(key)) {
      return defaultValue
    }

    const field = this.fields.get(key)
    return field.bool_value === true
  }

  /**
   * @description 
   *  return `string` when `key` exists with a `string` value
   *  otherwise return `empty string` unless `defaultValue` is specified
   * @param `key` 
   * @param `defaultValue` 
   * @returns 
   */
  public textVal(key: string, defaultValue: string = ""): string {
    if (this.empty(key)) {
      return defaultValue
    }

    const field = this.fields.get(key)
    const value = field.text_value
    if (this.isTextEmpty(value)) {
      return defaultValue
    }

    return value
  }

  /**
   * @description 
   *  return `string` when `key` exists with a `string` value
   *  otherwise return `empty string` unless `defaultValue` is specified
   * @param `key` 
   * @param `defaultValue` 
   * @returns 
   */
  public fileVal(key: string, defaultValue: string = ""): string {
    if (this.empty(key)) {
      return defaultValue
    }

    const field = this.fields.get(key)

    const value = field.file_value
    if (this.isTextEmpty(value)) {
      return defaultValue
    }

    return value
  }

  /**
   * @description 
   *  return `array of Field` when `key` exists with a `collection` value
   *  otherwise return `empty array` unless `defaultValue` is specified
   * @param `key` 
   * @param `defaultValue` 
   * @returns 
   */
  public collectionVal(key: string, defaultValue: Field[] = []): Field[] {
    if (this.empty(key)) {
      return defaultValue
    }

    const field = this.fields.get(key)

    const value = field.collection_value
    if (this.isCollectionEmpty(value)) {
      return defaultValue
    }

    return value
  }

  /**
   * @description 
   *  try to get field possible `text` value
   *  if field current type is supported (`text_val` or `file_val`)
   *  otherwise return `empty string` unless `defaultValue` is specified
   * 
   *  useful for getting hypermedia related sources (e.g: link, file, etc)
   * @param `key` 
   * @param `defaultValue` 
   * @returns 
   */
  public tryTextVal(key: string, defaultValue: string = ""): string {
    if (!this.has(key)) {
      return defaultValue
    }

    const field = this.fields.get(key)
    const supported = new Set<string>([FieldType.TEXT, FieldType.FILE])
    if (!supported.has(field.type)) {
      return defaultValue
    }

    if (field.type === FieldType.FILE) {
      return this.fileVal(key, defaultValue)
    }

    return this.textVal(key, defaultValue)
  }

  private parseFields(values: InputValue[]): Map<string, FieldValue> {
    const fields = new Map<string, FieldValue>()

    values.forEach((field: any) => {
      if (field.type !== FieldType.COLLECTION) {
        fields.set(field.key, field)
      } else {
        const collections = [] as Field[]

        field.collection_value.forEach((collection: any) => {
          const values = [] as InputValue[]

          collection.attributes.forEach((attribute: any) => {
            values.push(attribute)
          })

          collections.push(new Field(values))
        })

        fields.set(field.key, {
          ...field,
          collection_value: collections
        })
      }
    })

    return fields
  }

  private isTextEmpty(t: string): boolean {
    return typeof t !== "string" || t === undefined || t === null || t === ""
  }

  private isCollectionEmpty(c: Field[]): boolean {
    return !Array.isArray(c) || c === undefined || c === null || c.length === 0
  }

}
