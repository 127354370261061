import * as Yup from "yup"
import * as YupPhone from "yup-phone-lite"
import { ValidationError as YupValidationError } from "yup"
import type { CountryCode } from "libphonenumber-js/types"

declare module "yup" {
  interface StringSchema {
    phone(countryCode?: CountryCode | CountryCode[], errorMessage?: string): StringSchema;
  }
}

type Rule = {
  [k: string]: any;
}

type Option = {
  abortEarly?: boolean
}

type ValidatorResult = {
  validate: Validator
}

type Validator = (data: any) => ValidationResult

type ValidationResult = {
  error?: ValidationError
  data?: any
}

class ValidationError extends Error {
  private issues: Map<string, string[]>

  constructor(message: string, issues: Map<string, string[]> = new Map()) {
    super(message)
    this.issues = issues
  }

  public hasIssues(): boolean {
    return this.issues && this.issues.size > 0
  }

  public getIssues(): Map<string, string[]> {
    return this.issues
  }

}

export function useValidator(rule: Rule, opt?: Option): [ValidatorResult] {
  const {
    abortEarly = false,
  } = opt || {
    abortEarly: false,
  }

  const yupValidator = Yup.object(rule)

  function validate(data: any): ValidationResult {
    try {
      const res = yupValidator.validateSync(data, {
        abortEarly,
      })
      return {
        data: res
      }
    } catch (err: any) {
      if (!(err instanceof YupValidationError)) {
        return {
          error: new ValidationError(err.message)
        }
      }

      const issues = new Map<string, string[]>()
      for (const e of err.inner) {
        const key = e.path.replace("[", ".").replace("].", ".")
        issues.set(key, e.errors)
      }

      return {
        error: new ValidationError(err.message, issues)
      }
    }
  }

  const validator = {
    validate
  }
  return [validator]
}

export {
  Yup as Validator,
  YupPhone as PhoneValidator,
  ValidationError,
  ValidationResult
}