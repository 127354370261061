import * as React from "react"
import { Status } from "../services/Status"
import { Blog as BlogService } from "../services/api/Blog"
import { Field } from "../services/Field"

const blogService = new BlogService({
  host: process.env.REACT_APP_API_HOST
})

type PageProps = {
  slug: string
}

type PageItem = {
  slug: string
  title: string
  content?: string
  mode: string
  fields: Field
}

type PageState = {
  loading: boolean
  error: Status
  detail: PageItem
}

export function usePage(props?: PageProps): [PageState, React.Dispatch<React.SetStateAction<PageState>>] {
  const { slug } = props

  const [page, setPage] = React.useState<PageState>({
    loading: false,
    error: undefined,
    detail: {
      slug: "",
      title: "",
      mode: "",
      content: undefined,
      fields: new Field(),
    } as PageItem,
  })

  async function getPage(slug: string) {
    setPage((prevState) => {
      return {
        ...prevState,
        error: undefined,
        loading: true,
      }
    })
    const getPage = await blogService.GetPage({ slug })
    setPage((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (getPage.error) {
      setPage((prevState) => {
        return {
          ...prevState,
          error: getPage.error,
        }
      })
      return
    }

    setPage((prevState) => {
      return {
        ...prevState,
        detail: getPage.data.detail,
      }
    })
  }

  React.useEffect(() => {
    getPage(slug)
  }, [slug])

  return [page, setPage]
}
