import * as React from "react"
import { HiEye, HiEyeSlash } from "react-icons/hi2"
import { Base } from "./Base"
import {
  InputSize,
  InputSizeProps,
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("container", "flex flex-row rounded outline outline-1 outline-slate-100")
defaultTheme.set("element", "w-full border rounded-tl rounded-bl outline outline-1 outline-slate-100")
defaultTheme.set("toggler", "bg-white border rounded-tr rounded-br cursor-pointer")
defaultTheme.set("disabled-true", "cursor-not-allowed")
defaultTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
defaultTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

const staTheme = new Map<string, string>()
staTheme.set("container", "flex flex-row rounded outline outline-1 outline-slate-100")
staTheme.set("element", "w-full bg-sta-cloud border-1 md:border-2 border-sta-primary rounded-tl rounded-bl outline outline-1 outline-slate-100")
staTheme.set("toggler", "bg-white border rounded-tr rounded-br cursor-pointer")
staTheme.set("disabled-true", "cursor-not-allowed")
staTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
staTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

class InputSecretStyle {

  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""
  private elementClassNames: string | undefined = ""

  public buildContainer(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style = theme.get("container")
    }

    return style
  }

  public buildElement(): string | undefined {
    let style: string | undefined = undefined

    if (this.elementClassNames) {
      return this.elementClassNames
    }

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("element")) {
      style = theme.get("element")
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public buildToggler(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("toggler")) {
      style = theme.get("toggler")
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public setTheme(theme: string): InputSecretStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): InputSecretStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): InputSecretStyle {
    this.size = size
    return this
  }

  public setElementClassNames(elementClassNames?: string): InputSecretStyle {
    this.elementClassNames = elementClassNames
    return this
  }
}

type InputSecretProps = {
  id?: string
  name?: string
  placeholder?: string
  defaultValue?: string | number | ReadonlyArray<string> | undefined
  value?: string | ReadonlyArray<string> | number | undefined
  style?: React.CSSProperties | undefined
  size?: InputSizeProps
  className?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  toggleAble?: boolean
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

const styleBuilder = new InputSecretStyle()

export function InputSecret(props: InputSecretProps): React.JSX.Element {
  const {
    toggleAble = false,
    disabled = false,
    size = InputSize.MEDIUM,
    className,
  } = props

  styleBuilder
    .setElementClassNames(className)
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const containerClassNames = styleBuilder.buildContainer()
  const elementClassNames = styleBuilder.buildElement()
  const togglerClassNames = styleBuilder.buildToggler()

  const [show, setShow] = React.useState(false)

  const handleToggle = () => {
    if (!toggleAble) {
      return
    }

    setShow((prevState) => {
      return !prevState
    })
  }

  return (
    <div className={containerClassNames}>
      <Base
        type={show ? "text" : "password"}
        className={elementClassNames}
        {...props} />

      {
        toggleAble &&
        <div className={togglerClassNames} onClick={handleToggle}>
          {
            !show &&
            <HiEye className="w-6 h-6 text-gray-500" />
          }

          {
            show &&
            <HiEyeSlash className="w-6 h-6 text-gray-500" />
          }
        </div>
      }
    </div>
  )
}