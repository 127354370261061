import * as React from "react"
import {
  CarouselVariant,
  CarouselVariantProps,
  NavigationPosition,
  NavigationPositionProps
} from "./Resource"
import { CarouselNavigation } from "./Navigation"
import { CarouselPagination } from "./Pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"
import { Swiper as S } from "swiper/types"
import "swiper/css"

type CarouselProps = {
  children?: {
    items: CarouselItem[]
  }
  className?: string
  autoplay?: boolean
  autoplayDelay?: number
  grabable?: boolean
  navigable?: boolean
  paginable?: boolean
  variant?: CarouselVariantProps
  navPosition?: NavigationPositionProps
  slidesPerView?: number | 'auto'
  spaceBetween?: number
  breakpoints?: {
    [w: number]: CarouselOption
  }
  onLoad?: () => void
  onPageSelect?: ({ page }: { page: number }) => void
}

type CarouselItem = {
  children?: React.ReactNode
  className?: string
}

type CarouselOption = {
  slidesPerView?: number | 'auto'
  spaceBetween?: number
}

export function Carousel(props: CarouselProps): React.JSX.Element {
  const {
    autoplay = false,
    autoplayDelay = 5000,
    grabable = false,
    navigable = false,
    paginable = false,
    variant = CarouselVariant.PRIMARY,
    navPosition = NavigationPosition.MIDDLE,
    breakpoints = {},
    children = {
      items: []
    },
    onLoad = () => { },
    onPageSelect = () => { },
  } = props

  const items = children.items
  const [carousel, setCarousel] = React.useState<S>(null)
  const [navigation, setNavigation] = React.useState({
    activeIndex: 0,
    isBeginning: false,
    isEnd: false,
  })

  const handlePageSelect = (p: number) => {
    if (!carousel) {
      return
    }

    carousel.slideTo(p)

    onPageSelect && onPageSelect({
      page: p
    })
  }

  const handlePrev = () => {
    if (!carousel) {
      return
    }

    carousel.slidePrev()

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })

    onPageSelect && onPageSelect({
      page: carousel.realIndex
    })
  }

  const handleNext = () => {
    if (!carousel) {
      return
    }
    carousel.slideNext()

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })

    onPageSelect && onPageSelect({
      page: carousel.realIndex
    })
  }

  React.useEffect(() => {
    if (!carousel) {
      return
    }

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })

    if (carousel.autoplay) {
      if (autoplay) {
        carousel.autoplay.start()
      } else {
        carousel.autoplay.stop()
      }
    }
  }, [carousel, autoplay])

  React.useEffect(() => {
    if (!carousel) {
      return
    }

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })

    carousel.update()
  }, [carousel, items])

  function setupCarousel(c: S) {
    setCarousel(c)

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: c.realIndex,
        isBeginning: c.isBeginning,
        isEnd: c.isEnd,
      }
    })
  }

  return (
    <CarouselContainer>
      <CarouselNavigation
        variant={variant}
        enabled={navigable}
        position={navPosition}
        prevDisabled={navigation.isBeginning}
        nextDisabled={navigation.isEnd}
        onPrev={handlePrev}
        onNext={handleNext} />

      <Swiper
        enabled
        modules={[Autoplay]}
        autoplay={{
          delay: autoplayDelay,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        slidesPerView={props.slidesPerView}
        spaceBetween={props.spaceBetween}
        breakpoints={breakpoints}
        grabCursor={grabable}
        wrapperClass={props.className}
        // @issue: https://stackoverflow.com/questions/70802682/parents-overflowhidden-doesnt-show-childs-box-shadow
        className="h-full"
        onSwiper={(s) => {
          setupCarousel(s)

          onLoad && onLoad()
        }}
        onRealIndexChange={(s) => {
          setupCarousel(s)
        }}>
        {
          items.map((item, i: number) => {
            return (
              <SwiperSlide key={`carousel-item-${i}`} className={item.className}>
                {item.children}
              </SwiperSlide>
            )
          })
        }
      </Swiper>

      <CarouselPagination
        enabled={paginable}
        totalItems={items.length}
        activeIndex={carousel?.realIndex}
        onSelect={handlePageSelect} />
    </CarouselContainer>
  )
}

type CarouselContainerProps = {
  children?: React.ReactNode
}

function CarouselContainer(props: CarouselContainerProps): React.JSX.Element {
  return (
    <div className="relative w-full h-full">
      {props.children}
    </div>
  )
}

export {
  SwiperSlide as CarouselItem
}