import * as React from "react"
import { Modal } from "../../components/Modal"
import { useLocalStorage, useTimeout } from "../../hooks"
import { Newsletter } from "./Newsletter"

type NewsletterModalProps = {
  logoImage: string
  portalId?: string
  maxShow?: number
  delayTimer?: number
}

export function NewsletterModal(props: NewsletterModalProps): React.JSX.Element {
  const {
    logoImage,
    portalId = "overlay-root",
    maxShow = 1,
    delayTimer = 7000,
  } = props

  const [form, setForm] = React.useState({
    interact: false
  })
  const [modal, setModal] = useLocalStorage("newsletter_modal", {
    version: 1,
    createdAt: new Date().getTime(),
    opened: false,
    totalShow: 0,
  })

  function openModal() {
    setModal((prevState) => {
      return {
        ...prevState,
        opened: true,
        totalShow: prevState.totalShow + 1
      }
    })
  }

  function closeModal() {
    setModal((prevState) => {
      return {
        ...prevState,
        opened: false,
      }
    })
  }

  useTimeout(() => {
    if (modal.totalShow >= maxShow) {
      return
    }

    openModal()
  }, delayTimer)

  return (
    <Modal portalId={portalId}
      size="2xl"
      opened={modal.opened}
      onClose={() => { closeModal() }}
      closeable>
      {{
        header: <>
          <div className="flex flex-row justify-between gap-2">
            <img alt="logo" src={logoImage} className="w-24 md:w-36 h-12 md:h-16" />
          </div>
        </>,
        content: <>
          <div className="flex flex-col gap-4 justify-center items-center">
            <Newsletter
              onInteract={() => {
                setForm((prevState) => {
                  return {
                    ...prevState,
                    interact: true
                  }
                })
              }} />

            {
              !form.interact &&
              <button className="text-base underline"
                onClick={() => { closeModal() }}>
                No thanks
              </button>
            }
          </div>
        </>
      }}
    </Modal>
  )
}