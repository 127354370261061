import * as React from "react"
import { useScroll } from "framer-motion"

type TimelineProps = {
  children?: {
    items?: TimelineItem[]
  }
  className?: string
}

type TimelineItem = {
  heading?: React.ReactNode
  content?: React.ReactNode
}

type TItem = TimelineItem & {
  active: boolean
}

export function Timeline(props: TimelineProps): React.JSX.Element {
  const {
    children = {
      items: []
    }
  } = props

  const containerRef = React.useRef()
  const [items, setItems] = React.useState<TItem[]>([])
  const [progress, setProgress] = React.useState(0)
  const { scrollYProgress } = useScroll({
    axis: "y",
    target: containerRef,
    offset: ["start 0.5", "end 0.5"],
  })

  scrollYProgress.onChange((p: number) => {
    setProgress(p)
  })

  React.useEffect(() => {
    setItems(children.items.map((item, i: number) => {
      return {
        ...item,
        active: i === 0,
      }
    }))
  }, [children.items])

  React.useEffect(() => {
    const current = selectProgress(progress, children.items.length - 1)

    setItems(children.items.map((item, i: number) => {
      return {
        ...item,
        active: i <= current,
      }
    }))
  }, [children.items, progress])

  function selectProgress(p: number, total: number): number {
    const base = 100
    const progress = p * base
    const progressPeritem = base / total

    return Math.round(progress / progressPeritem)
  }

  function selectMilestone(x: number) {
    setItems((prevState) => {
      return prevState.map((item, i: number) => {
        return {
          ...item,
          active: i <= x
        }
      })
    })
  }

  return (
    <ol className={props.className} ref={containerRef}>
      {
        items.map((item, i: number) => {
          return (
            <React.Fragment key={`timeline-item-${i}`}>
              <Item
                id={`timeline-${i + 1}`}
                heading={item.heading}
                content={item.content}
                active={item.active}
                onClick={() => { selectMilestone(i) }}>

              </Item>
            </React.Fragment>
          )
        })
      }
    </ol>
  )
}

type ItemProps = {
  children?: React.ReactNode
  heading?: React.ReactNode
  content?: React.ReactNode
  id?: string
  active?: boolean
  onClick?: () => void
}

function Item(props: ItemProps): React.JSX.Element {
  const {
    active = false,
  } = props

  const handleClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <>
      <li className={
        "relative w-full border-l-4 last:border-transparent " +
        (active ? "border-sta-secondary" : "border-sta-gray")
      }>
        <div className={
          "absolute z-[1] -ml-3.5 w-6 h-6 rounded-full border-2  " +
          (active ? "bg-sta-secondary border-sta-secondary" : "bg-sta-gray border-sta-gray")
        } />
        <div className="flex flex-col gap-2 pl-7 pb-14">
          <button className={
            "w-fit font-bold text-white rounded-3xl px-3 py-1 " +
            (active ? "bg-sta-primary" : "bg-sta-gray")
          }
            onClick={handleClick}>
            {props.heading}
          </button>
          {/*
            @note: disable hidden because it's glitching on mobile  
            hidden md:block 
          */}
          <div className={
            "text-sm md:text-lg " +
            (active ? "text-black block" : "text-sta-gray")
          }>
            {props.content}
          </div>
        </div>
      </li>
    </>
  )
}