import * as React from "react"
import { GoLinkExternal } from "react-icons/go"
import { Accordion } from "../components/Accordion"
import { Button, ButtonLink } from "../components/Button"
import { Container } from "../components/Container"
import { Meta } from "../components/Meta"
import { Navigation } from "../components/Page"
import { Viewer } from "../components/Viewer"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, useHashFragment, usePage } from "../hooks"

const benefitDefaultIcon = "https://placehold.co/24x24.jpg?text=I&font=roboto"

export default function Membership(): React.JSX.Element {
  const [page] = usePage({
    slug: "membership"
  })
  const [navItems] = React.useState([
    {
      to: "/membership#join",
      content: "Be a Member",
      ref: React.useRef<HTMLDivElement>(),
    },
    {
      to: "/membership#benefit",
      content: "Benefits of Membership",
      ref: React.useRef<HTMLDivElement>(),
    },
    {
      to: "/membership#stc",
      content: "Standard Trading Conditions",
      ref: React.useRef<HTMLDivElement>(),
    },
    {
      to: "/members-directory",
      content: "Meet The Members",
    },
    {
      to: "/membership-application",
      content: "Membership Application",
    }
  ])
  const [join, setJoin] = React.useState({
    items: []
  })
  const [benefit, setBenefit] = React.useState({
    items: []
  })
  const [stc, setStc] = React.useState({
    opened: false,
  })

  React.useEffect(() => {
    const joinItems = page.detail.fields.collectionVal("JOIN_ITEMS")
    const benefitItems = page.detail.fields.collectionVal("BENEFIT_ITEMS")

    setJoin((prevState) => {
      return {
        ...prevState,
        items: joinItems.map((join, i: number) => {
          return {
            title: join.textVal("title", ""),
            description: join.textVal("description"),
            fee: join.textVal("fee", ""),
          }
        }),
      }
    })
    setBenefit((prevState) => {
      return {
        ...prevState,
        items: benefitItems.map((benefit, i: number) => {
          return {
            name: benefit.textVal("name", ""),
            description: benefit.textVal("description", ""),
            icon: benefit.tryTextVal("icon", benefitDefaultIcon),
          }
        }),
      }
    })
  }, [page])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "Membership"}`,
          description: `Unlock a world of advantages with STA Member Benefits. Elevate your business through exclusive networking opportunities, cutting-edge resources, and industry insights. Enjoy tailored solutions, amplified visibility, and cost-saving collaborations, designed to empower your success in the ever-evolving transport sector.`,
        }}
      </Meta>

      <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
        <div className="w-full h-full flex justify-start items-center">
          <Container size="md">
            <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
              {page.detail.title || "Membership"}
            </p>
          </Container>
        </div>
      </div>

      <Navigation items={navItems} loading={page.loading} sticky />

      <div ref={navItems[0].ref} id="join" className="scroll-my-28">
        {
          page.detail.fields.boolVal("JOIN_VISIBILITY") &&
          <>
            <div></div> {/* @note: do not remove to avoid newlseter section contain below element attributes */}
            <div className="bg-white py-10 md:py-20">
              <Container size="md">
                <div className="grid grid-cols-12 gap-6 md:gap-12 2xl:gap-24">
                  <div className="col-span-12 lg:col-span-7 xl:col-span-8">
                    <div className="flex flex-col gap-4 md:gap-8">
                      <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                        {page.detail.fields.textVal("JOIN_HEADING_TEXT", `Join Us`)}
                      </p>
                      {
                        !page.detail.fields.empty("JOIN_SUBHEADING_TEXT") &&
                        <p className="font-bold text-base md:text-2xl text-sta-secondary">
                          {page.detail.fields.textVal("JOIN_SUBHEADING_TEXT", `Be part of the local transport community.`)}
                        </p>
                      }
                      <div className="html-viewer html-viewer text-sm md:text-lg flex flex-col gap-2"
                        dangerouslySetInnerHTML={{
                          __html: page.detail.fields.textVal(
                            "JOIN_CONTENT_TEXT",
                            `
                          <p>
                            Unlock a world of advantages with STA Member Benefits.
                          </p>
                          <p>
                            Elevate your business through exclusive networking opportunities, cutting-edge resources, and industry insights.
                          </p>
                          <p>
                            Enjoy tailored solutions, amplified visibility, and cost-saving collaborations, designed to empower your success in the ever-evolving transport sector.
                          </p>
                        `
                          )
                        }}>
                      </div>
                      <div className="flex flex-row justify-start items-center gap-2 my-4 md:my-8">
                        <div>
                          <ButtonLink to="/membership-application" variant="primary">
                            <span className="uppercase">
                              {page.detail.fields.textVal("JOIN_REGISTER_CTA_TEXT", `Be A Member`)}
                            </span>
                          </ButtonLink>
                        </div>
                        <div>
                          <ButtonLink to="/members-directory" variant="secondary">
                            <span className="uppercase">
                              {page.detail.fields.textVal("JOIN_MEMBER_CTA_TEXT", `Members Directory`)}
                            </span>
                          </ButtonLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-5 xl:col-span-4">
                    <div className="grid grid-cols-12 gap-4 auto-rows-fr">
                      {
                        join.items.map((item, i: number) => {
                          return (
                            <React.Fragment key={`join-item-${i}`}>
                              <div className="col-span-6 lg:col-span-12">
                                <div className="bg-sta-navy rounded-3xl p-4 md:p-8 h-full min-h-[160px] md:min-h-[280px]">
                                  <div className="flex flex-col justify-center items-between gap-10 md:gap-20">
                                    <p className="font-bold text-white text-base md:text-2xl">
                                      {item.title}
                                    </p>
                                    <div className="flex flex-col gap-2">
                                      <p className="font-bold text-sta-secondary text-2xl md:text-5xl">
                                        {item.fee}
                                      </p>
                                      <p className="font-light text-sta-cloud text-sm md:text-lg">
                                        {item.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        })
                      }
                    </div>
                    <div className={"html-viewer flex flex-col gap-2 my-4 "}
                      dangerouslySetInnerHTML={{
                        __html: page.detail.fields.textVal(
                          "JOIN_NOTE",
                          `
                        <p>* Fees are pro-rated bi-annually based on month of application received.</p>
                        <p>Pro-rate schedule:</p>
                        <ul>
                          <li>Jan - June: $500</li>
                          <li>July - Dec: $250</li>
                        </ul>
                      `
                        )
                      }}>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </>
        }
      </div>

      <div ref={navItems[1].ref} id="benefit" className="scroll-my-28">
        {
          page.detail.fields.boolVal("BENEFIT_VISIBILITY") &&
          <div className="bg-sta-navy py-10 md:py-20">
            <Container size="md">
              <div className="flex flex-col gap-4">
                <p className="w-fit font-bold text-white text-xl md:text-4xl border-b-4 border-sta-secondary">
                  {page.detail.fields.textVal("BENEFIT_HEADING_TEXT", `Member's benefit`)}
                </p>
                {
                  !page.detail.fields.empty("BENEFIT_SUBHEADING_TEXT") &&
                  <p className="text-white text-base md:text-xl">
                    {page.detail.fields.textVal("BENEFIT_SUBHEADING_TEXT", `Amplifying success with exclusive benefits`)}
                  </p>
                }
              </div>

              <div className="my-5 md:my-10">
                <div className="hidden md:grid grid-cols-12 gap-4 2xl:gap-8 auto-rows-fr">
                  {
                    benefit.items.map((benefit, i: number) => {
                      return (
                        <React.Fragment key={`benefit-item-${i}`}>
                          <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                            <div className="bg-white rounded-3xl p-6 h-full">
                              <div className="flex flex-col gap-4 md:gap-8">
                                <div className="flex flex-row justify-start items-center gap-4">
                                  <div className="w-fit h-full">
                                    <div className="flex justify-center items-center w-14 h-14 bg-sta-secondary rounded-full">
                                      <img src={benefit.icon} alt="benefit" className="w-9 h-9 text-white" />
                                    </div>
                                  </div>
                                  <p className="font-bold text-sta-primary text-base md:text-2xl md:leading-7">
                                    {benefit.name}
                                  </p>
                                </div>
                                <div className="html-viewer text-black text-base md:text-lg break-words"
                                  dangerouslySetInnerHTML={{
                                    __html: benefit.description
                                  }}>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
                <div className="w-full flex md:hidden">
                  <Accordion alwaysOpen>
                    {{
                      items: benefit.items.map((benefit) => {
                        return {
                          heading: <>
                            <div className="flex flex-row justify-start items-center gap-4">
                              <div className="w-fit h-full">
                                <div className="flex justify-center items-center w-14 h-14 bg-sta-secondary rounded-full">
                                  <img src={benefit.icon} alt="benefit" className="w-9 h-9 text-white" />
                                </div>
                              </div>
                              <p className="font-bold text-sta-primary text-base md:text-2xl md:leading-7">
                                {benefit.name}
                              </p>
                            </div>
                          </>,
                          content: <>
                            <div className="html-viewer" dangerouslySetInnerHTML={{
                              __html: benefit.description
                            }}>
                            </div>
                          </>
                        }
                      })
                    }}
                  </Accordion>
                </div>
              </div>
            </Container>
          </div>
        }
      </div>

      <div ref={navItems[2].ref} id="stc" className="scroll-my-28">
        {
          page.detail.fields.boolVal("STC_VISIBILITY") &&
          <div className="bg-white py-10 md:py-20">
            <Container size="md">
              <div className="flex flex-col gap-4 md:gap-8">
                <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                  {page.detail.fields.textVal("STC_HEADING_TEXT", `STA Standard Trading Conditions`)}
                </p>
                {
                  !page.detail.fields.empty("STC_SUBHEADING_TEXT") &&
                  <p className="text-base md:text-2xl text-sta-primary">
                    {page.detail.fields.textVal("STC_SUBHEADING_TEXT")}
                  </p>
                }
                <Viewer full={stc.opened} height="250px">
                  <div className="html-viewer text-sm md:text-lg flex flex-col gap-4"
                    dangerouslySetInnerHTML={{
                      __html: page.detail.fields.textVal(
                        "STC_CONTENT_TEXT",
                        `
                        <p>
                          Within the transportation industry, small companies or small and medium-sized enterprises are often subjected to unreasonable compensation terms from clients, which might lead to shortage of funds. This problem is especially acute for smaller companies with little capital, which might cause them to face bankruptcy.
                        </p>
                        <p>
                          In lieu of this problem, STA has specially formed a sub-committee (Sub-committee Chairman Mr. Tony Png Teck Seng, Committee member as Mr. Ng Boon Hong, Mr. Tay Siew Ngee, Mr. Lau Swee Khim and Mr. Peter Teo) to consult legal advisors (Mr. Seah Chwee Lim) to draft up standardized trading conditions, and also seek advice from insurance companies (Sin Seet Insurance Agency Pte Ltd) for their feedback.
                          For a period of six months, there were weekly meetings and discussions, and finally the completed set of documents was brought up during the Annual General Meeting (AGM) and was approved and recognized as the “STA STANDARD TRADING CONDITIONS”.
                        </p>
                        <p>
                          On 20 July 2006, the STA STANDARD TRADING CONDITIONS was revealed to members at the SAFRA Bukit Merah Auditorium. From this launch, members are now able to consult and comply to the guidelines stated within the STA STANDARD TRADING CONDITIONS during the drafting of contracts with their clients, to better safeguard their interests.
                        </p>
                        <p>
                          The STA STANDARD TRADING CONDITIONS is published in the Straits Times and Lian He Zhao Bao (联合早报) on 13 Jun 2007.
                        </p>
                      `
                      )
                    }}>
                  </div>
                </Viewer>
                <div className="flex justify-start items-center gap-4 my-2">
                  <ButtonLink variant="primary" appendClassNames="flex flex-row gap-2 justify-between items-center"
                    to={page.detail.fields.tryTextVal("STC_VIEW_CTA_TO", `/file/STA-Standard-Trading-Conditions.pdf`)}
                    target="_blank" hideCrawl>
                    <span className="uppercase">
                      {page.detail.fields.textVal("STC_VIEW_CTA_TEXT", `View Document`)}
                    </span>
                    <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                  </ButtonLink>
                  <Button variant="primary" appendClassNames="flex md:hidden"
                    onClick={() => {
                      setStc((prevState) => {
                        return {
                          ...prevState,
                          opened: !prevState.opened
                        }
                      })
                    }}>
                    <span className="uppercase">
                      {stc.opened ? "Read Less" : "Read More"}
                    </span>
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        }
      </div>

      <NewsletterSection />
    </>
  )
}