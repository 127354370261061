import * as React from "react"

type GoogleMapEmbedProps = {
  id?: string
  title?: string
  query?: string
  zoom?: number
  appendClassNames?: string
}

export function GoogleMapEmbed(props: GoogleMapEmbedProps): React.JSX.Element {
  const {
    appendClassNames = "",
    query = "",
    zoom = 13
  } = props

  return (
    <iframe
      scrolling="no" frameBorder={0}
      marginHeight={0} marginWidth={0}
      id={props.id} title={props.title}
      src={`https://maps.google.com/maps/?q=${query}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`}
      className={"w-full h-full " + appendClassNames}>

    </iframe>
  )
}