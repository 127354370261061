import * as React from "react"
import { GoogleMapEmbed } from "./GoogleMapEmbed"
import { MapViewerProvider, MapViewerProviderProps } from "./Resource"

type MapViewerProps = {
  id?: string
  title?: string
  query?: string
  zoom?: number
  appendClassNames?: string
  provider?: MapViewerProviderProps
  config?: Map<string, any>
}

export const MapViewer: React.FC<MapViewerProps> = (props: MapViewerProps): React.JSX.Element => {
  const {
    provider = MapViewerProvider.GOOGLE_MAP_EMBED,
  } = props

  return (
    <>
      {
        (() => {
          switch (provider) {
            case MapViewerProvider.GOOGLE_MAP_EMBED:
              return <GoogleMapEmbed {...props} />
            default:
              return null
          }
        })()
      }
    </>
  )
}