import * as React from "react"
import { Button } from "../../components/Button"
import { FieldNotif } from "../../components/Field"
import { InputEmail } from "../../components/Input"
import { toast } from "../../components/Toast"
import { StatusCode } from "../../services/Status"
import { SubscribeEmailParam } from "../../services/Newsletter"
import { Newsletter as NewsletterService } from "../../services/api/Newsletter"
import { useRecaptcha } from "../../hooks"

const newsletterService = new NewsletterService({
  host: process.env.REACT_APP_API_HOST
})

const defaultFormData = {
  email: ""
}

type NewsletterProps = {
  onInteract?: () => void
}

export function Newsletter(props: NewsletterProps): React.JSX.Element {
  const {
    onInteract = () => { },
  } = props

  const [form, setForm] = React.useState({
    data: {
      ...defaultFormData,
    },
    info: new Map<string, string[]>(),
    loading: false,
    successSubmit: false,
    failSubmit: false
  })
  const recaptcha = useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  async function subscribeEmail(param: SubscribeEmailParam) {
    setForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const subscribeRes = await newsletterService.SubscribeEmail(param)
    setForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })

    if (subscribeRes.error) {
      if (subscribeRes.error.Is(StatusCode.INVALID_PARAM)) {
        setForm((prevState) => {
          return {
            ...prevState,
            info: subscribeRes.error.validationErrors
          }
        })
      }

      const warningError = subscribeRes.error.Any([StatusCode.INVALID_PARAM, StatusCode.RESOURCE_EXISTS])
      if (warningError) {
        toast.warning(
          <>
            <p>There's invalid data being sent</p>
            <p><span className="font-bold">{subscribeRes.error.message}</span></p>
          </>,
          { className: "toast-mesasge" }
        )
        return
      }

      setForm((prevState) => {
        return {
          ...prevState,
          failSubmit: true,
        }
      })
      toast.error(
        <>
          <p>Failed subscribe newsletter</p>
          <p><span className="font-bold">{subscribeRes.error.message}</span></p>
        </>,
        { className: "toast-mesasge" }
      )
      return
    }

    setForm((prevState) => {
      return {
        ...prevState,
        data: {
          ...defaultFormData
        },
        info: new Map<string, string[]>(),
        successSubmit: true
      }
    })

    toast.success(
      <>
        <p>Success subscribe newsletter</p>
        <p><span className="font-bold">Your request is being processed</span></p>
      </>,
      { className: "toast-mesasge" }
    )
  }

  const handleFieldBlur = ({ field }: FieldNewsletterBlur) => {
    setForm((prevState) => {
      const info = prevState.info
      info.delete(field)

      return {
        ...prevState,
        info
      }
    })
  }

  const handleFormSubmit = async ({ data }: FormNewsletterSubmit) => {
    try {
      onInteract && onInteract()

      const token = await recaptcha.execute("submit")
      if (!token) {
        toast.warn(
          "Failed verify reCAPTCHA, please try again.",
          { className: "toast-mesasge" }
        )
        return
      }

      subscribeEmail({
        email: data.email,
        verificationToken: token,
      })
    } catch (e: any) {
      console.error(e)
      toast.error(
        `Unexpected error happened: ${e.message}`,
        { className: "toast-mesasge" }
      )
    }
  }

  return (
    <FormNewsletter
      loading={form.loading}
      data={form.data}
      info={form.info}
      disableSubmit={recaptcha.loading}
      successSubmit={form.successSubmit}
      failSubmit={form.failSubmit}
      onFieldBlur={handleFieldBlur}
      onSubmit={handleFormSubmit}
    />
  )
}

type FormNewsletterProps = {
  children?: React.ReactNode
  data: FormNewsletterData
  info?: Map<string, string[]>
  loading?: boolean
  disableSubmit?: boolean
  successSubmit?: boolean
  failSubmit?: boolean
  onSubmit?: (m: FormNewsletterSubmit) => void
  onFieldBlur?: (m: FieldNewsletterBlur) => void
}

type FormNewsletterData = {
  email?: string
}

type FormNewsletterSubmit = {
  data: FormNewsletterData
}

type FieldNewsletterBlur = {
  field: string
}

export function FormNewsletter(props: FormNewsletterProps): React.JSX.Element {
  const {
    loading = false,
    disableSubmit = false,
    successSubmit = false,
    failSubmit = false,
    data,
    info = new Map(),
    onFieldBlur = () => { },
    onSubmit = () => { },
  } = props

  const [form, setForm] = React.useState(data)

  const handleNewsletterSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    onSubmit && onSubmit({
      data: form
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  function blurField(field: string) {
    onFieldBlur && onFieldBlur({
      field
    })
  }

  React.useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        data: data
      }
    })
  }, [data])

  return (
    <form method="POST" onSubmit={handleNewsletterSubmit}>
      <div className="w-full h-full flex flex-col gap-4 md:gap-8 justify-center items-center">
        {
          (!successSubmit && !failSubmit) &&
          <>
            <div className="flex flex-col gap-1 md:gap-2.5 text-center">
              <p className="font-black text-2xl md:text-3xl xl:text-5xl text-sta-navy">
                Join our newsletter
              </p>
              <p className="text-base md:text-lg xl:text-xl text-black">
                Subscribe to our email mailing list & stay updated with latest updates, news and events
              </p>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-2 justify-center items-center">
              <div className="w-full md:w-fit relative">
                <InputEmail
                  name="email"
                  placeholder="Enter your email address here..."
                  appendClassNames="!w-full md:!w-fit md:min-w-[400px] !bg-sta-navy !text-white !border-sta-navy"
                  value={form.email}
                  onChange={handleInputChange}
                  onBlur={() => { blurField("email") }}
                  disabled={loading}
                  required />
                {
                  info.has("email") && info.get("email").length > 0 &&
                  <div className="absolute">
                    <FieldNotif>{info.get("email")[0]}</FieldNotif>
                  </div>
                }
              </div>
              <Button type="submit" variant="secondary"
                appendClassNames="w-full md:w-fit !rounded-md md:!rounded-lg"
                disabled={loading || disableSubmit}>
                <span className="uppercase">
                  Sign Up Now
                </span>
              </Button>
            </div>
          </>
        }

        {
          successSubmit &&
          <div className="mt-4 flex flex-col gap-1 md:gap-2.5 text-center">
            <p className="font-black text-2xl md:text-5xl text-sta-navy">
              Success! You're now subscribed.
            </p>
            <p className="text-base md:text-xl text-black">
              Your subscription has been successfully confirmed. <br />
              Check your inbox soon for great emails from us.
            </p>
          </div>
        }
      </div>
    </form>
  )
}