import * as React from "react"
import { To } from "react-router-dom"
import { IoChevronForwardOutline } from "react-icons/io5"
import { Link } from "../Router"
import { Base } from "./Base"
import { Container } from "../Container"

type FooterProps = {
  children?: React.ReactNode
  logoImage?: string
  logoUrl: To
  copyright?: string
  contactItems?: React.ReactNode[]
  contactUrl?: To
  menuItems?: FooterItem[]
  mediaItems?: MenuItem[]
  linkItems?: MenuItem[]
}

type FooterItem = {
  heading: React.ReactNode
  headingUrl?: To
  items: MenuItem[]
}

type MenuItem = {
  to: To
  target?: string
  content: React.ReactNode
  hideCrawl?: boolean
}

export function Footer(props: FooterProps): React.JSX.Element {
  const {
    contactItems = [],
    contactUrl,
    menuItems = [],
    mediaItems = [],
    linkItems = [],
    copyright = "Singapore Transport Association. All Rights Reserved.",
  } = props

  return (
    <Base appendClassNames="font-inter bg-white">
      <Container size="md">
        <div className="grid grid-cols-12 gap-4 md:gap-8 py-8">
          <div className="col-span-12 lg:col-span-4">
            <div className="flex flex-row md:flex-col gap-4 justify-between items-center md:items-start">
              <div className="flex flex-col gap-2">
                <Link to={props.logoUrl} className="no-underline text-sm">
                  <img alt="logo" src={props.logoImage} className="w-32 md:w-80 h-16 md:h-40" />
                </Link>
              </div>

              {
                mediaItems.length > 0 &&
                <div className="flex flex-row gap-4 md:gap-6 justify-start items-center">
                  {
                    mediaItems.map((mediaItem, i: number) => {
                      return (
                        <React.Fragment key={`media-item-${i}`}>
                          <Link className="flex justify-center items-center bg-sta-gray hover:bg-sta-primary text-sta-primary hover:text-white rounded-full w-11 h-11"
                            to={mediaItem.to} target={mediaItem.target} hideCrawl={mediaItem.hideCrawl}>
                            {mediaItem.content}
                          </Link>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              }
            </div>
          </div>
          <div className="col-span-12 lg:col-span-8 lg:p-4">
            <div className="grid grid-cols-12 gap-x-2 md:gap-x-8 gap-y-6 md:gap-y-12">
              {
                contactItems.length > 0 &&
                <div className="col-span-12 xl:col-span-4 flex flex-col gap-4">
                  {
                    contactUrl ?
                      <Link to={contactUrl} hideCrawl
                        className="w-fit font-bold text-black text-base md:text-2xl border-b-4 border-sta-primary">
                        Contact
                      </Link>
                      :
                      <p className="w-fit font-bold text-black text-base md:text-2xl border-b-4 border-sta-primary">
                        Contact
                      </p>
                  }
                  <ul className="flex flex-col justify-center items-start gap-2">
                    {
                      contactItems.map((contactItem, i: number) => {
                        return (
                          <li key={`contact-item-${i}`}>
                            {contactItem}
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              }
              {
                menuItems.map((menuItem, i: number) => {
                  return (
                    <div key={`footer-menu-${i}`} className="col-span-6 xl:col-span-4 flex flex-col gap-4">
                      {
                        menuItem.headingUrl ?
                          <Link to={menuItem.headingUrl} hideCrawl
                            className="w-fit font-bold text-black text-base md:text-2xl border-b-4 border-sta-primary">
                            {menuItem.heading}
                          </Link>
                          :
                          <p className="w-fit font-bold text-black text-base md:text-2xl border-b-4 border-sta-primary">
                            {menuItem.heading}
                          </p>
                      }

                      {
                        menuItem.items.length > 0 &&
                        <ul className="flex flex-col justify-center items-start gap-2">
                          {
                            menuItem.items.map((listItem, j: number) => {
                              return (
                                <li key={`footer-item-${j}`}>
                                  <div className="flex flex-row gap-2 justify-start items-center">
                                    <IoChevronForwardOutline className="block h-4 w-4 text-sta-primary" aria-hidden="true" />
                                    <Link to={listItem.to} target={listItem.target} hideCrawl={listItem.hideCrawl}
                                      className="text-sm md:text-lg text-black hover:text-sta-primary">
                                      {listItem.content}
                                    </Link>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </Container>
      <Container size="full" appendClassNames="bg-sta-primary">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-2 py-7">
          {
            copyright &&
            <p className="font-light text-sm md:text-base text-white">
              {copyright}
            </p>
          }

          {
            linkItems.length > 0 &&
            <div className="flex flex-row gap-6 md:gap-12">
              {
                linkItems.map((linkItem, i: number) => {
                  return (
                    <React.Fragment key={`link-item-${i}`}>
                      <Link to={linkItem.to} target={linkItem.target} hideCrawl={linkItem.hideCrawl}
                        className="block no-underline font-light text-sm md:text-base text-white hover:text-sta-gray">
                        {linkItem.content}
                      </Link>
                    </React.Fragment>
                  )
                })
              }
            </div>
          }
        </div>
      </Container>
    </Base>
  )
}