import * as React from "react"

type Header = {
  intersector?: React.MutableRefObject<HTMLElement>
  factor: number
  meta: Map<string, string>
}

export const HeaderContext = React.createContext<[Header | undefined, React.Dispatch<React.SetStateAction<Header | undefined>>]>(undefined)
const { Consumer, Provider } = HeaderContext

type HeaderProviderProps = {
  children?: React.ReactNode
  intersector?: React.MutableRefObject<HTMLElement>
  factor?: number
  meta?: Map<string, string>
}

export function HeaderProvider(props: HeaderProviderProps): React.JSX.Element {
  const {
    intersector,
    factor = 1,
    meta = new Map(),
  } = props

  const header = React.useState({
    intersector,
    factor,
    meta,
  })

  return (
    <Provider value={header}>
      {props.children}
    </Provider>
  )
}

export {
  Consumer as HeaderConsumer
}
