import * as React from "react"
import { LazyLoadComponent } from "react-lazy-load-image-component"

type LoaderProps = {
  children?: React.ReactNode
  lazy?: boolean
}

export function Loader(props: LoaderProps) {
  const {
    lazy = false
  } = props

  return (
    <>
      {
        lazy ?
          <LazyLoadComponent
            delayMethod="throttle"
            delayTime={300}
            threshold={100}
            useIntersectionObserver>
            {props.children}
          </LazyLoadComponent>
          : props.children
      }
    </>
  )
}
