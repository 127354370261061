import * as React from "react"
import { FiCalendar } from "react-icons/fi"
import { GoLinkExternal } from "react-icons/go"
import { useParams } from "react-router-dom"
import { Badge } from "../components/Badge"
import { ButtonLink } from "../components/Button"
import { Carousel } from "../components/Carousel"
import { Container } from "../components/Container"
import { Error } from "../components/Page"
import { Meta } from "../components/Meta"
import { Skeleton } from "../components/Skeleton"
import { StatusCode } from "../services/Status"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, useHashFragment } from "../hooks"
import { Blog as BlogService } from "../services/api/Blog"
import { dateFormat } from "../typeconv"

const blogService = new BlogService({
  host: process.env.REACT_APP_API_HOST
})

type ArticleItem = {
  slug: string
  title: string
  mode: string
  url?: string
  content?: string
  thumbnailUrl?: string
  attachmentUrls?: string[]
  collectionUrls?: string[]
  createdAt: Date
  categories: {
    name: string
  }[]
}

export default function ArticleDetail(): React.JSX.Element {
  const { slug } = useParams()
  const [article, setArticle] = React.useState({
    loading: false,
    error: undefined,
    detail: {} as ArticleItem,
  })

  async function getArticle(slug: string) {
    setArticle((prevState) => {
      return {
        ...prevState,
        error: undefined,
        loading: true
      }
    })
    const getArticle = await blogService.GetArticle({
      slug
    })
    setArticle((prevState) => {
      return {
        ...prevState,
        loading: false
      }
    })
    if (getArticle.error) {
      setArticle((prevState) => {
        return {
          ...prevState,
          error: getArticle.error,
        }
      })
      return
    }

    setArticle((prevState) => {
      return {
        ...prevState,
        detail: getArticle.data.detail,
      }
    })
  }

  function openImage(url: string) {
    window.open(url, "_blank")
  }

  React.useEffect(() => {
    getArticle(slug)
  }, [slug])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      {
        article.error &&
        <>
          <Meta>
            {{
              title: (article.error.Is(StatusCode.RESOURCE_NOTFOUND) ? `404 Article not found` : "Oops, unexpected error happened"),
              description: (article.error.Is(StatusCode.RESOURCE_NOTFOUND) ? `404 Article not found` : article.error.message),
            }}
          </Meta>

          <Error
            title={article.error.Is(StatusCode.RESOURCE_NOTFOUND) ? "404" : `${article.error.code}`}
            subtitle={article.error.Is(StatusCode.RESOURCE_NOTFOUND) ? "Oops, article not found!" : article.error.message} />
        </>
      }

      {
        !article.error &&
        <>
          <Meta>
            {{
              title: `${article.detail.title || "News"}`,
              description: `${article.detail.title || "News"}`,
            }}
          </Meta>

          <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
            <div className="w-full h-full flex justify-start items-center">
              <Container size="md">
                {
                  article.loading ?
                    <>
                      <Skeleton width="40%" height="20px" rounded />
                    </>
                    :
                    <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
                      News
                    </p>
                }
              </Container>
            </div>
          </div>

          <Container size="md">
            <div className="py-10 md:py-20 flex flex-col gap-4">
              <div className="flex flex-col gap-4 md:gap-12">
                <div className="flex flex-col gap-2">
                  {
                    article.loading ?
                      <Skeleton width="50%" height="20px" rounded />
                      :
                      <p className="w-fit font-bold text-sta-primary text-xl md:text-4xl">
                        {article.detail.title}
                      </p>
                  }
                  {
                    !article.loading && article.detail.categories && article.detail.categories.length > 0 &&
                    <div className="flex flex-row flex-wrap gap-2 my-2">
                      {
                        article.detail.categories.map((category, i: number) => {
                          return (
                            <React.Fragment key={`category-item-${i}`}>
                              <Badge variant="secondary" size="sm">
                                <span className="uppercase">
                                  {category.name}
                                </span>
                              </Badge>
                            </React.Fragment>
                          )
                        })
                      }
                    </div>
                  }
                </div>

                <div className="flex flex-row gap-4 justify-start items-center">
                  {
                    article.loading ?
                      <Skeleton width="40px" height="40px" rounded />
                      :
                      <div className="w-12 h-12 flex justify-center items-center rounded-full bg-sta-secondary text-white">
                        <FiCalendar className="w-6 h-6" aria-label="hidden" />
                      </div>
                  }
                  <div className="h-full">
                    {
                      article.loading ?
                        <>
                          <Skeleton width="20%" height="10px" rounded />
                        </>
                        :
                        <p className="font-semibold text-sta-secondary text-sm md:text-lg">
                          <>{dateFormat(article.detail.createdAt, 'dddd, D MMMM YYYY')}</>
                        </p>
                    }
                  </div>
                </div>

                <div className="w-full h-full">
                  {
                    article.loading ?
                      <div className="flex flex-col gap-2">
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                        <Skeleton width="100%" height="10px" rounded />
                      </div>
                      :
                      <div className={"leading-7 html-viewer"}
                        dangerouslySetInnerHTML={{
                          __html: article.detail.content
                        }}>

                      </div>
                  }

                  {
                    article.detail.mode === "url" &&
                    <div className="my-4">
                      <ButtonLink to={article.detail.url} target="_blank">
                        <span className="uppercase">
                          Read More
                        </span>
                      </ButtonLink>
                    </div>
                  }
                </div>

                {
                  article.detail.attachmentUrls && article.detail.attachmentUrls.length > 0 &&
                  <div className="flex flex-row flex-wrap gap-2">
                    {
                      article.detail.attachmentUrls.map((url, i: number) => {
                        return (
                          <React.Fragment key={`attachment-item-${i}`}>
                            <ButtonLink to={url} target="_blank"
                              variant={i % 2 === 0 ? "primary" : "secondary"}
                              appendClassNames="flex flex-row gap-2 justify-between items-center">
                              <span className="uppercase">
                                {`Attachment ${i + 1}`}
                              </span>
                              <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                            </ButtonLink>
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                }
              </div>

              <hr className="border-2 border-sta-secondary my-4" />

              {
                article.detail.collectionUrls && article.detail.collectionUrls.length > 0 &&
                <Carousel
                  className="grid grid-flow-col auto-rows-fr justify-start items-center"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1280: {
                      slidesPerView: 3,
                      spaceBetween: 35,
                    },
                    1536: {
                      slidesPerView: 4,
                      spaceBetween: 35,
                    },
                  }}
                  variant="secondary"
                  navPosition='tr'
                  autoplay grabable navigable>
                  {{
                    items: article.detail.collectionUrls.map((url) => {
                      return {
                        children: <>
                          <div className="cursor-pointer w-full h-full p-1" onClick={() => { openImage(url) }}>
                            <img alt="collection" src={url} className="h-full aspect-video object-cover object-center rounded-2xl" />
                          </div>
                        </>
                      }
                    })
                  }}
                </Carousel>
              }
            </div>
          </Container>

          <NewsletterSection />
        </>
      }
    </>
  )
}
