import { RouteProps, Route } from "react-router-dom"
import loadable from "@loadable/component"
import { Spinner } from "./components/Spinner"
import { NavigateParams } from "./components/Router"

import Guest from "./layouts/Guest"
import NotFound from "./pages/NotFound"
import Home from "./pages/Home"
import About from "./pages/About"
import Constitution from "./pages/Constitution"
import Council from "./pages/Council"
import Membership from "./pages/Membership"
import Member from "./pages/Member"
import News from "./pages/News"
import ArticleDetail from "./pages/ArticleDetail"
import Contact from "./pages/Contact"
import PageDetail from "./pages/PageDetail"

const Loader = <Spinner size="md" appendClassNames="m-4" labeled />
export const Page = loadable((props: { name: string }) => import(`./pages/${props.name}`), {
  cacheKey: (props) => props.name,
  fallback: Loader
})

const guests: RouteProps[] = [
  { path: "/", element: <Home /> },
  { path: "/about-us", element: <About /> },
  { path: "/constitution", element: <Constitution /> },
  { path: "/the-council", element: <Council /> },
  { path: "/membership", element: <Membership /> },
  { path: "/members-directory", element: <Member /> },
  { path: "/membership-application", element: <Page name="Register" /> },
  { path: "/news-events", element: <News /> },
  { path: "/article", element: <NavigateParams replace to="/news-events" /> },
  { path: "/article/:slug", element: <ArticleDetail /> },
  { path: "/contact-us", element: <Contact /> },
  { path: "/p", element: <NavigateParams replace to="/" /> },
  { path: "/p/:slug", element: <PageDetail /> },
  { path: "*", element: <NotFound /> },
]

export const routes: RouteProps[] = [
  {
    path: "/",
    element: <Guest />,
    children: guests.map((route, i: number) => {
      return <Route {...route} key={`guest-item-${i}`} />
    })
  },
]