import * as React from "react"

type ViewerProps = {
  children?: React.ReactNode
  appendClassNames?: string
  height?: string
  full?: boolean
}

export function Viewer(props: ViewerProps): React.JSX.Element {
  const {
    full = false,
    height = "100%",
  } = props

  const h = full ? "100%" : height
  const appendClassNames = props.appendClassNames ? props.appendClassNames : ""

  return (
    <div className={"relative w-full overflow-y-hidden text-ellipsis " + appendClassNames}>
      <div className="md:!h-full" style={{ height: h }}>
        {props.children}
      </div>
    </div>
  )
}